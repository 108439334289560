.about-page {
  .about {
    &__mmm {
      @include container();
      display: flex;
      position: relative;
      padding-bottom: 160px;
      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 60px;
      }
    }
    &__box {
      width: 50%;
      background-image: url("../../img/Desktop/about-mmm.svg");
      background-repeat: no-repeat;
      height: 620px;
      position: relative;
      margin-left: 150px;
      @media (max-width: 1700px) {
        background-size: 73%;
        height: 580px;
      }
      @media (max-width: 1366px) {
        //  background: unset;
      }
      @media (max-width: 1170px) {
        background-size: 90%;
      }
      @media (max-width: 1024px) {
        background-size: contain;
        // margin-left: 0;
        width: 100%;
        height: 480px;
      }
      @media (max-width: 580px) {
        height: 330px;
      }
      // @media (max-width: 460px) {
      //   background-size: 200px;
      //   width: 240px;
        margin-left: 90px;
      //   height: 260px;
      // }
      h2 {
        width: 10%;
        position: absolute;
        top: 93px;
        left: -100px;
        //padding-right: 100px;
        font-size: 48px;
        line-height: 72px;
        font-family: $font-pb;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg); // vertically align copy.
        writing-mode: vertical-rl;
        @include textGradientPurple();
        span {
          @include textGradientGreen();
        }
        @media (max-width: 1024px) {
          font-size: 36px;
          line-height: 44px;
          top: 40px;
          left: -65px;
        }
        @media (max-width: 580px) {
          font-size: 20px;
          line-height: 30px;
        }
        @media (max-width: 460px) {
          left: -40px;
        }
      }
      .about__inner {
        opacity: 1;
        top: 129px;
        left: -181px;
        @media (max-width: 1366px) {
          background: unset;
        }
        @media (max-width: 1100px) {
          top: 65px;
          left: -255px;
        }
        @media (max-width: 460px) {
          left: -288px;
        }
        &--bg {
          @media (max-width: 1366px) {
            background-image: url("../../img/Desktop/pageAbout/Group-0.svg");
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 0;
            left: 0;
            background-position: top center;
            & > div {
              display: none;
            }
          }
        }
      }
    }
    &__bl {
      //width: 590px;
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 90px;
      margin-left: 90px;
      @media (max-width: 1700px) {
        margin-top: 10px;
        margin-left: 20px;
      }
      @media (max-width: 1024px) {
        width: 100%;
        padding: 0 20px;
      }
      p {
        margin-bottom: 80px;
        font-size: 18px;
        line-height: 36px;
        position: relative;
        @media (max-width: 1700px) {
          font-size: 16px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 30px;
          margin-bottom: 30px;
        }
        &:before {
          content: "";
          position: absolute;
          top: 12px;
          left: -25px;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: $linear-green;
        }
      }
    }
    &__cards {
      display: flex;
      padding-bottom: 200px;
      @include container();
      padding-left: 162px;
      padding-right: 162px;
      @media (max-width: 1700px) {
        padding-bottom: 100px;
      }
      @media (max-width: 1024px) {
        padding-bottom: 80px;
      }
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    &__card {
      width: 33.333%;
      margin-right: 33px;
      box-shadow: 0px 10px 20px -5px rgba(80, 0, 255, 0.15);
      border-radius: 20px;
      background: #fff;
      padding: 60px;
      @media (max-width: 1700px) {
        padding: 40px;
      }
      @media (max-width: 1366px) {
        margin-right: 20px;
      }
      @media (max-width: 1024px) {
        padding: 40px 20px 20px;
      }
      @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
      b {
        font-size: 24px;
        line-height: 36px;
        display: block;
        margin-bottom: 40px;
        font-family: $font-pb;
        @include textGradientPurple();
        @media (max-width: 1700px) {
          font-size: 22px;
          line-height: 33px;
          margin-bottom: 20px;
        }
        @media (max-width: 1024px) {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
    &__bg {
      width: 50%;
      background: url("../../img/Desktop/about/Rectangle.svg");
      background-repeat: no-repeat;
      background-size: 681px 484px;
      position: relative;
      height: 500px;
      @media (max-width: 1700px) {
        background-size: contain;
        background: url("../../img/Desktop/about/Rectangle1440.svg");
        background-repeat: no-repeat;
      }
      @media (max-width: 1366px) {
        background-size: contain;
        width: 100%;
        height: 500px;
        position: relative;
      }
      @media (max-width: 480px) {
        height: 320px;
      }
      //@media (max-width: 1024px) {
      //  background-size: cover;
      //}
      //@media (max-width: 580px) {
      //  background: url("../../img/Desktop/about/Mob/Rectangle-mob.svg");
      //  background-repeat: no-repeat;
      //  //background-size: cover;
      //  width: 100%;
      //  height: 100%;
      //}
    }
    &__elements {
    }
    &__element {
      margin-bottom: 180px;
      display: flex;
      position: relative;
      @media (max-width: 1366px) {
        flex-direction: column-reverse;
        width: 100%;
        margin-bottom: 0;
      }
      @media (max-width: 480px) {
        margin-bottom: 20px;
      }
      &:nth-child(1) {
        .about__desc {
          &:before {
            top: -500px;
            right: 100px;
            background-image: url("../../img/Desktop/about/1.png");
            animation: triangle-about-1 6s infinite ease-in-out;
          }
        }
        .about__bg {
          @media (max-width: 1366px) {
            background-image: url("../../img/Desktop/pageAbout/Group-1.svg");
            background-repeat: no-repeat;
          }
        }
      }
      &:nth-child(2) {
        flex-direction: row-reverse;
        @media (max-width: 1366px) {
          flex-direction: column-reverse;
          align-items: flex-end;
        }
        .about__desc {
          @media (max-width: 1366px) {
            width: 98%;
          }
          @media (max-width: 480px) {
            width: 94%;
          }
          &:before {
            top: -400px;
            left: -200px;
            background-image: url("../../img/Desktop/about/2.png");
            animation: triangle-about-2 6s infinite ease-in-out;
          }
        }
        .about__bg {
          transform: scaleX(-1);
          @media (max-width: 1366px) {
            background-image: url("../../img/Desktop/pageAbout/Group-3.svg");
            background-repeat: no-repeat;
            transform: scaleX(1);
            width: 100%;
            background-position-x: right;
          }
        }
      }
      &:nth-child(3) {
        .about__desc {
          &:before {
            top: -450px;
            right: 0px;
            background-image: url("../../img/Desktop/about/3.png");
            width: 570px;
            animation: triangle-about-3 6s infinite ease-in-out;
          }
        }
        .about__bg {
          @media (max-width: 1366px) {
            background-image: url("../../img/Desktop/pageAbout/Group-2.svg");
            background-repeat: no-repeat;
          }
        }
      }
      .about__inner {
        opacity: 1;
        @media (max-width: 1366px) {
          display: none;
        }
      }
      .about__desc {
        margin-left: 120px;
        @media (max-width: 1366px) {
          width: 100%;
          margin-left: 20px;
        }
        @media (max-width: 480px) {
          margin-bottom: 20px;
        }
        &:before {
          content: "";
          position: absolute;
          z-index: -11;
          background-repeat: no-repeat;
          width: 520px;
          height: 1200px;
          @media (max-width: 1366px) {
            display: none;
          }
        }
        h3 {
          font-size: 36px;
          line-height: 54px;
          @include textGradientPurple();
          @media (max-width: 1024px) {
            font-size: 24px;
            line-height: 32px;
          }
        }
        p {
          max-width: 790px;
          width: 100%;
          @media (max-width: 1024px) {
            padding-right: 20px;
          }
        }
      }
      .about__inner--card {
        @media (max-width: 1366px) {
          display: none;
        }
        .about__vector-1 {
          top: unset;
          left: unset;
          bottom: 55px;
          right: 150px;
        }
        .about__vector-2 {
          top: 0;
          left: 100px;
        }
        .about__vector-3 {
          top: 100px;
          left: 320px;
        }
        .about__vector-4 {
          top: 63px;
          left: 260px;
        }
      }
      .about__inner--cash {
        transform: scaleX(-1);
        .about__vector-1 {
          top: unset;
          left: unset;
          bottom: 100px;
          right: 150px;
        }
        .about__vector-2 {
          top: -90px;
          left: 450px;
        }
        .about__vector-3 {
          top: -60px;
          left: 120px;
        }
        .about__vector-4 {
          top: 90px;
          left: 260px;
          z-index: 1;
        }
        .about__vector-5 {
          top: 110px;
          left: 100px;
        }
      }
      .about__inner--bank {
        .about__vector-1 {
          top: unset;
          left: unset;
          bottom: 35px;
          right: 250px;
          z-index: 1;
        }
        .about__vector-2 {
          top: -135px;
          left: 545px;
        }
        .about__vector-3 {
          top: 43px;
          left: unset;
          right: 25px;
        }
        .about__vector-4 {
          top: 30px;
          left: 130px;
        }
      }
    }
    &__group {
      width: 50%;
    }
  }

  .about__vector-1--mob {
    @media (max-width: 580px) {
      display: block;
      left: 280px;
    }

    img {
      @media (max-width: 580px) {
        width: 120px;
      }
    }
  }

  .about__vector-2--mob {
    @media (max-width: 580px) {
      display: block;
      left: 350px;
    }

    img {

      @media (max-width: 580px) {
        width: 30px;
      }
    }
  }

  .about__vector-3--mob {
    @media (max-width: 580px) {
      display: block;
    }

    img {

      @media (max-width: 580px) {
        width: 40px;
      }
    }
  }

  .about__vector-4--mob {
    @media (max-width: 580px) {
      display: block;
      left: 300px;
    }

    img {

      @media (max-width: 580px) {
        width: 160px;
      }
    }
  }

  .about__vector-5--mob {
    @media (max-width: 580px) {
      display: block;
      left: 380px;
    }

    img {
      @media (max-width: 1024px) {
        width: 60%;
      }
      @media (max-width: 580px) {
        width: 160px;
      }
    }
  }
}
@keyframes triangle-about-1 {
  0% {
    top: -500px;
  }
  50% {
    top: -450px;
  }
  100% {
    top: -500px;
  }
}
@keyframes triangle-about-2 {
  0% {
    top: -400px;
  }
  50% {
    top: -350px;
  }
  100% {
    top: -400px;
  }
}
@keyframes triangle-about-3 {
  0% {
    top: -540px;
  }
  50% {
    top: -400px;
  }
  100% {
    top: -450px;
  }
}
