.steps {
  padding-top: 100px;
  padding-bottom: 40px;
  &__title {
    margin-bottom: 120px;
    text-align: center;
    @media (max-width: 480px) {
      width: 220px;
      margin: 0 auto 50px;
    }
    h2 {
      @include textGradientGreen;
      font-size: 48px;
      line-height: 72px;
      font-family: $font-pb;
      @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 32px;
      }
      span {
        color: white;
        -webkit-text-fill-color: aliceblue;
        font-size: 48px;
        line-height: 72px;
        @media (max-width: 1024px) {
          font-size: 28px;
          line-height: 32px;
        }
      }
    }
  }
  &__container {
    position: relative;
    @media (max-width: 1024px) {
      background-image: url("../../img/Vector2.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      //background-position: 0 0;
      //height: 924px;
      display: flex;
      justify-content: center;
    }
    &:before {
      content: "";
      position: absolute;
      top: -450px;
      right: 30px;
      background-image: url("../../img/partners/bg-tr.png");
      background-repeat: no-repeat;
      width: 424px;
      height: 724px;
      animation: float-2 5s infinite;
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
  &__line {
    svg {
      width: 100%;
    }
  }
  &__svg1 {
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__svg2 {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  &__items {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 162px;
    padding-right: 162px;
    @media (max-width: 1700px) {
      padding-left: 120px;
      padding-right: 120px;
    }
    @media (max-width: 1280px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      position: unset;
    }
  }
  &__item {
    max-width: 380px;
    width: 100%;
    max-height: 220px;
    height: 100%;
    padding: 20px 20px 35px;
    background-color: #181824;
    box-shadow: 0px 10px 20px -5px #0B0B11;
    color: #fff;
    border-radius: 20px;
    //position: absolute;
    @media (max-width: 1700px) {
      max-width: 320px;
      //width: 320px;
    }
    @media (max-width: 1024px) {
      max-width: 230px;
      width: 230px;
      margin-bottom: 30px;
    }
    &:nth-child(1) {
      margin-right: 25px;
      @media (max-width: 1024px) {
        margin-right: 0;
      }
    }
    &:nth-child(2) {
      margin-top: 200px;
      margin-right: 25px;
      @media (max-width: 1700px) {
        margin-top: 260px;
        margin-left: -50px;
      }
      @media (max-width: 1024px) {
        margin-right: 0;
        margin-left: 50px;
        margin-top: 0;
      }
    }
    &:nth-child(3) {
      margin-top: 50px;
      margin-right: 25px;
      @media (max-width: 1024px) {
        margin-right: 0;
        margin-top: 0;
      }
    }
    &:nth-child(4) {
      margin-top: 270px;
      @media (max-width: 1700px) {
        margin-top: 300px;
        margin-left: -50px;
      }
      @media (max-width: 1024px) {
        margin-left: 50px;
        margin-top: 0;
      }
    }
  }
  &__icon {
    margin-bottom: 15px;
    width: 50px;
    height: 72px;
    display: flex;
    align-items: center;
    @media (max-width: 1280px) {
      width: 30px;
      height: 42px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 27px;
    @media (max-width: 1700px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
