.error-page {
  background-image: url("../../img/bg-error.svg");
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  @media (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &__image {
    margin-bottom: 50px;
    svg {
      @media (max-width: 1024px) {
        height: 200px;
      }
    }
  }
  &__title {
    @include textGradientPurple();
    font-size: 36px;
    line-height: 60px;
    font-family: $font-pb;
    font-weight: 800;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 45px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 60px;
    margin-bottom: 40px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 45px;
    }
  }
  a {
    @include border-btn-gradient();
  }
}