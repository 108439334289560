.popup-countries {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  padding: 75px;
  background: $white;
  //background: #9acfea;
  width: 1380px;
  height: 80vh;
  margin: 0 auto;
  margin-top: 120px;
  -webkit-box-shadow: 0px 0px 10px 1px #000000;
  box-shadow: 0px 0px 10px 1px #000000;
  overflow-y: auto;
  display: none;
  @media (max-width: 1390px) {
    width: 100%;
    padding: 30px;
  }
  &::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: #F8F9FF;
    border-radius: 20px; /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    background: $linear-green-dark;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    //border: 3px solid orange;  /* creates padding around scroll thumb */
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    b {
      font-family: $font-pb;
      font-size: 48px;
      line-height: 72px;
      @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 52px;
      }
    }
  }
  &__closed {
    cursor: pointer;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    //row-gap: 30px;
    column-gap: 30px;
  }
  &__item {
    display: flex;
    align-items: center;
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      margin-bottom: 30px;
    }
    img {
      width: 30px;
      height: 30px;
    }
    span {
      margin-left: 20px;
      width: 230px;
      color: $black;
      @media (max-width: 1390px) {
        width: 165px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  &__region {
    margin-bottom: 80px;
    b {
      font-family: $font-pb;
      font-size: 24px;
      line-height: 36px;
      display: block;
      margin-bottom: 50px;
      @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
}