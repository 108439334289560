.main-banner {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  //height: 100vh;
  @media (max-width: 1700px) {
    margin-bottom: 120px;
  }
  @media (max-width: 1366px) {
    height: 100%;
  }
  @media (max-width: 480px) {
    margin-bottom: 200px;
  }
  &__bg {
    transform: translateY(0);
    transition: 3s all ease-in-out;
    position: relative;
    width: 100%;
    @media (max-width: 1024px) {
      width: 130%;
      left: -15%;
      top: 60px;
    }
    @media (max-width: 1024px) {
      width: 130%;
      left: -15%;
      top: 60px;
    }
    @media (max-width: 768px) {
      width: 140%;
      left: -20%;
    }
    @media (max-width: 620px) {
      width: 180%;
      left: -40%;
    }
    @media (max-width: 480px) {
      width: 180%;
      left: -40%;
    }
    @media (max-width: 430px) {
      width: 200%;
      left: -50%;
    }
    @media (max-width: 430px) {
      width: 200%;
      left: -50%;
    }
    @media (max-width: 390px) {
      width: 220%;
      left: -55%;
    }
    @media (max-width: 360px) {
      width: 245%;
      left: -70%;
    }
    img {
      width: 100%;
      //height: 100%;
    }
  }
  &__video {
    //height: 100vh;
    //border: 1px solid #000;
    //@media (max-width: 1024px) {
    //
    //}
    #bg-video {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      //height: 100%;
      //min-width: 100%;
      //min-height: 100%;
      //width: auto;
      //height: 100vh;
      z-index: 0;
      transition: 1s opacity;
      //animation: 5s opacityVideo  ease-in-out;
      @media (max-width: 1366px) {
        display: none;
      }
    }
  }
  &__inner {
    width: 100%;
    //height: 100vh;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 15.5%;
    left: 0;
    right: 0;
    text-align: center;
    //transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 3s all ease-in-out;
    //@media (max-width: 1700px) {
    //  top: 28.5%;
    //}
    //@media (max-width: 1366px) {
    //  top: 12.5%;
    //}
    &--up {
      top: -50%;
    }
    &--partners {
      top: 36.5%;
      .main-banner__title,
      .main-banner__text {
        -webkit-text-fill-color: aliceblue;
        color: #fff;
      }
      .main-banner__title {
        max-width: 1060px;
        width: 100%;
      }
      .main-banner__text {
        max-width: 760px;
        width: 100%;
      }
    }
  }
  &__title {
    font-family: $font-pb;
    font-size: 76px;
    line-height: 100px;
    width: 800px;
    text-align: center;
    background: $linear-dark-purple;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: $purple;
    margin-bottom: 40px;
    @media (max-width: 1366px) {
      font-size: 62px;
      line-height: 80px;
    }
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 50px;
    }
    @media (max-width: 530px) {
      width: 280px;
    }
  }
  &__text {
    width: 632px;
    text-align: center;
    background: $linear-dark-purple;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: $purple;
    font-size: 24px;
    line-height: 42px;
    @media (max-width: 1366px) {
      font-size: 20px;
      line-height: 30px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 30px;
      width: 100%;
      padding: 0 20px;
    }
  }
  &__wrap {
    width: 100%;
    position: absolute;
    top: 500px;
    //transition: linear;
    -webkit-transform:translateZ(1px);
    -moz-transform:translateZ(1px);
    -o-transform:translateZ(1px);
    transform:translateZ(1px);
  }
  &__block {
    position: relative;
    transform: perspective(1000px);
    transform-style: preserve-3d;
    height: 1000px;
    //top: 280px;
    // background: rgba(0%, 0%, 0%, .3);
    @media (max-width: 1700px) {
      height: unset;
    }
  }
  &__btns {
    margin-top: 50px;
  }
  &__register {
    @include bg-btn-gradient();
    font-size: 20px;
    line-height: 30px;
    margin-right: 25px;
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  &__singIn {
    border: 1px solid $green;
    font-size: 20px;
    line-height: 30px;
    border-radius: 100px;
    padding: 13px 39px;
    color: $green;
    @media (max-width: 1024px) {
      padding: 9px 30px;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
.forms-main {
  position: absolute;
  left: 50%;
  top: 240px;
  //bottom: 0;
  //bottom: -120px;
  z-index: 30;
  box-shadow: inset 0px 2px 10px rgba(80, 0, 255, 0.2);
  transform: translate(-50%, 0%);
  transition: all 0.5s ease-in;
  //padding-bottom: 40%;
  @media (max-width: 1700px) {
    top: 120px;
  }
  @media (max-width: 1366px) {
    top: 0;
  }
  @media (max-width: 768px) {
    top: -80px;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 0 20px;
    margin-top: 20px;
  }
  //@media (max-width: 1366px) {
  //  bottom: -250px;
  //  transform: translate(-50%, -50%);
  //}
  &__container {
    position: relative;
    width: 667px;
    height: fit-content;
    border-radius: 20px;
    background-clip: padding-box;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid #5000FF;
    box-shadow: 0px 4px 10px rgba(80, 0, 255, 0.1);
    @media (max-width: 768px) {
      width: 440px;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: -15;
      top: -15px;
      bottom: -15px;
      left: -15px;
      right: -15px;
      border-radius: 20px;
      background: $linear-purple;
      @media (max-width: 480px) {
        top: -5px;
        bottom: -5px;
        left: -5px;
        right: -5px;
      }
    }
  }
  &__controls {
    display: flex;
    background: #FDFDFD;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  &__item {
    text-align: center;
    font-family: $font-pb;
    width: 50%;
    height: 87px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 5px -5px 15px -6px rgba(80, 0, 255, 0.1);
    @media (max-width: 768px) {
      height: 65px;
    }
    span {
      background: $linear-dark-purple;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: $purple;
      font-size: 20px;
      line-height: 30px;
      @media (max-width: 1700px) {
        font-size: 18px;
        line-height: 27px;
      }
      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
    &--active {
      background: #ffffff;
      box-shadow: none;
    }
    &:nth-child(1) {
      border-top-left-radius: 20px;
    }
    &:nth-child(2) {
      border-top-right-radius: 20px;
    }
  }
  &__wrapper {
    height: 640px;
    position: relative;
    @media (max-width: 1700px) {
      height: 640px;
    }
    @media (max-width: 768px) {
      height: 620px;
    }
  }
  @keyframes float {
    50% {
      transform: translate(0, 20px);
    }
  }
  @keyframes rotate {
    50% {
      transform: rotate3d(2, -1, -1, -0.2turn);
    }
  }
  @keyframes heart {
    0% {
      transform: scale(1.5);
    }
    20% {
      transform: scale(2);
    }
    30% {
      transform: scale(1.5);
    }
    40% {
      transform: scale(2);
    }
    100% {
      transform: scale(1.5);
    }
  }

  @keyframes loveAnimation {
    0% {
      transform: scale(1,1);
    }
    50% {
      transform: scale(1.2,1.2);
    }
    100% {
      transform: scale(1,1);
    }
  }


  @keyframes emailAnimation {
    0% {
      transform: scale(1,1) translate(0,-2px);
    }
    25% {
      transform: scale(1,1) translate(0,2px);
    }
    50% {
      transform: scale(1.2,.8) translate(0,2px);
    }
    75% {
      transform: scale(1,1) translate(0,2px);
    }
    100% {
      transform: scale(1,1) translate(0,-2px);
    }
  }
}
.form-send {
  padding: 50px 40px 40px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @media (max-width: 768px) {
    padding: 20px 10px;
  }
  &__wrapper {
    position: relative;
  }
  &__input {
    position: relative;
    label {
      position: absolute;
      font-size: 24px;
      line-height:  36px;
      pointer-events: none;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
      color: #9AA4E2;
      @media (max-width: 1700px) {
        font-size: 20px;
        line-height: 30px;
      }
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    input {
      width: 100%;
      height: 80px;
      background: #FCFCFE;
      border-radius: 10px;
      border: none;
      box-shadow: inset 2px 0px 15px -8px rgba(80, 0, 255, 0.25), inset 0px 2px 10px -5px rgba(80, 0, 255, 0.25);
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: #0C0025;
      font-weight: bold;
      padding: 30px;
      @media (max-width: 1700px) {
        font-size: 20px;
        line-height: 30px;
        height: 60px;
      }
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        height: 50px;
      }
      &:focus ~ label,
      &:valid ~ label  {
        top: 0px;
        font-size: 16px;
        line-height: 24px;
        @media (max-width: 1700px) {
          font-size: 14px;
          line-height: 21px;
        }
        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 15px;
        }
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__btn {
    border: none;
    border-radius: 50px;
    background: linear-gradient(270deg, #5000FF 0%, #9A6BFF 100%);
    padding: 13px 70px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 768px) {
      padding: 10px 50px;
      font-size: 12px;
      line-height: 18px;
    }
    &:hover {
      background: linear-gradient(270deg, #3900B6 0%, #8F5BFF 100%);
    }
  }
  &__openVideo {
    background: inherit;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $purple;
    @media (max-width: 768px) {
      font-size: 10px;
      line-height: 15px;
    }
  }
}
.form-receive-money {
  padding: 50px 40px 40px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  @media (max-width: 768px) {
    padding: 20px 10px;
  }
  span {
    line-height: 27px;
    @include textGradientPurple();
    margin-bottom: 60px;
    display: block;
    max-width: 400px;
    width: 100%;
  }
  &__inner {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: auto;
    //.form-send__btn {
    //
    //}
  }
  .form-send__input {
    margin-bottom: 30px;
  }
}
.currencySelect {
  display: none;
}

.custom-select-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
  //max-width: 110px;
  display: inline-block;
}
.custom-select {
  position: relative;
  display: flex;
  flex-direction: column;
}
.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 13px;
  font-size: 20px;
  font-weight: 400;
  color: #333;
  height: 44px;
  background-color: rgb(238, 240, 244);
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
  @media (max-width: 1700px) {
    height: 44px;
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 14px;

  }

  &:after {
    content: "";
    top: 22px;
    right: -45px;
    background-image: url("../../img/Desktop/Icon/Chevron_Down.svg");
    background-repeat: no-repeat;
    width: 16px; 
    height: 16px;
    margin-left: 8px;
    transition: 1s all ease-in-out;
    @media (max-width: 1700px) {
      top: 10px;
    }
  }
  &--active {
    border: 1px solid #805AD5;
    transition: none;
    border-bottom-right-radius: 0px;
    &:after {
      transform: rotate(-180deg);
    }
  }
}

.custom-select__trigger span {
  //position: relative;
  //bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 0px;
  }
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border-top: 0; 
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
}
.custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.custom-option {
  position: relative;
  display: block;
  padding: 10px 10px;
  font-size: 13px;
  font-weight: 400;
  color: #333;
  line-height: 1.1;
  cursor: pointer;
  transition: all 0.5s;
  height: 100%;
}
.custom-option:hover {
  cursor: pointer;
  background-color: #b2b2b2;
}
.custom-option.selected {
  color: #ffffff;
  background-color: #9AA4E2;
}
.flag {
  height: 17px;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.flag-ch {
  margin-left: 4px;
  margin-right: 14px;
}
.additional {
  padding: 20px 20px 30px;
  @media (max-width: 480px) {
    padding-right: 0;
  }
  &__desc {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: -20px;
      left: 13px;
      height: 100%;
      width: 1px;
      background: #9AA4E2;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      //background: #FFFFFF;
      z-index: 2;
      box-shadow: inset 0px 4px 4px rgba(80, 0, 255, 0.15);
    }
    &:nth-child(1) {
      margin-top: 0;
      &:before {
        background: #FFFFFF url("../../img/Desktop/Icon/Minus.svg");
        background-repeat: no-repeat;
        background-position: center;      }
    }
    &:nth-child(2) {
      margin-top: 28px;
      &:before {
        background: #FFFFFF url("../../img/Desktop/Icon/Equally.svg");
        background-repeat: no-repeat;
        background-position: center;      }
    }
    &:nth-child(3) {
      margin-top: 28px;
      &:before {
        background: #FFFFFF url("../../img/Desktop/Icon/Multiply.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  &__inner {
    font-size: 18px;
    line-height: 27px;
    padding-left: 45px;
    font-family: $font-pl;
    @media (max-width: 1700px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 768px) {
      font-size: 10px;
      line-height: 15px;
      margin: auto 0;
      height: 10px;
    }
    @media (max-width: 480px) {
      padding-left: 35px;
    }
  }
  &__support {
    position: relative; 
    color: #808080;
    font-size: 14px;
    line-height: 21px;
    font-family: $font-pl;
    @media (max-width: 1700px) {
      font-size: 12px;
      line-height: 21px;
    }
    @media (max-width: 1700px) {
      font-size: 10px;
      line-height: 21px;
    }
  }
}
.block-anim-right {
  position: absolute;
  bottom: 170px;
  right: 350px;
  width: 150px;
  height: 136px;
  background: url("../../img/Desktop/Icon/icon_anim/Frame-2.svg");
  z-index: 200;
  animation: float 3s ease-out infinite;
  background-repeat: no-repeat;
  @media (max-width: 1700px) {
    background-size: 80%;
    right: 150px;
  }
}
.block-anim-left {
  position: absolute;
  bottom: 190px;
  left: 255px;
  width: 206px;
  height: 196px;
  background: url("../../img/Desktop/Icon/icon_anim/Frame-1.svg");
  z-index: 200;
  animation: float 7s ease-out infinite;
  background-repeat: no-repeat;
  @media (max-width: 1700px) {
    background-size: 80%;
    left: 100px;
  }
}
.block-anim-left-top {
  content: "";
  position: absolute;
  bottom: 700px;
  left: 505px;
  width: 140px;
  height: 136px;
  background: url("../../img/Desktop/Icon/icon_anim/Frame-3.svg");
  z-index: 20;
  animation: float 4s ease-out infinite;
  background-repeat: no-repeat;
  @media (max-width: 1700px) {
    background-size: 80%;
    bottom: 660px;
    left: 305px;
  }
}
.block-anim-right-top {
  content: "";
  position: absolute;
  bottom: 600px;
  right: 150px;
  width: 252px;
  height: 257px;
  background: url("../../img/Desktop/Icon/icon_anim/Frame.svg");
  z-index: 20;
  animation: float 6s ease-out infinite;
  background-repeat: no-repeat;
  @media (max-width: 1700px) {
    background-size: 80%;
    right: 100px;
  }
}
.block-anim-right,
.block-anim-left,
.block-anim-left-top,
.block-anim-right-top {
  @media (max-width: 1366px) {
    display: none;
  }
}
@keyframes opacityVideo {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
//@keyframes upBg {
//  0% {
//    transform: translateY(0);
//  }
//  100% {
//    transform: translateY(-700px);
//  }
//}