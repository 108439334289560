.news {
  padding-bottom: 80px;
  background: #fff;
  position: relative;
  &__decorAnim {
    position: absolute;
    top: -400px;
    left: 0;
    animation: triangle-top 6s infinite ease-in-out;
  }
  &__title {
    text-align: center;
    margin-bottom: 80px;
    font-size: 48px;
    line-height: 72px;
    font-family: $font-pb;
    @include textGradientGreen();
    @media (max-width: 1024px) {
      margin-bottom: 50px;
      font-size: 28px;
      line-height: 32px;
    }
    span {
      @include textGradientPurple();
    }
  }
  &__container {
    @include container();
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
      flex-direction: column;
      margin: auto;
    }
  }
  &__item {
    margin-right: 40px;
    margin-bottom: 40px;
    //width: 33.333%;
    width: calc((100%/3) - (80px/3));
    border: 1px solid #CED5FF;
    border-radius: 20px;
    background: #fff;
    min-height: 100%;
    overflow: hidden;
    @media (max-width: 1700px) {
      margin-right: 20px;
      width: calc((100%/3) - (40px/3));
    }
    @media (max-width: 1024px) {
      height: inherit;
    }
    @media (max-width: 768px) {
      height: inherit;
      width: 100%;
    }
    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }
  &__image {
    @media (max-width: 1700px) {
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__desc {
    padding: 40px;
    position: relative;
    min-height: 300px;
    height: max-content;
    display: flex;
    flex-direction: column;
    @media (max-width: 1700px) {
      padding: 40px 20px 30px 20px;
    }
    @media (max-width: 1024px) {
      padding: 20px;
      min-height: auto;
    }
  }
  &__group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    span {
      padding: 3px 13px;
      border: 1px solid #B6C0FF;
      border-radius: 6px;
      font-size: 16px;
      line-height: 24px;
      color: #B6C0FF;
      width: max-content;
      margin-right: 10px;
      margin-bottom: 10px;
      @media (max-width: 1700px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 18px;
        padding: 3px 10px;
      }
    }
  }
  &__full-title {
    font-size: 24px;
    line-height: 36px;
    font-family: $font-pb;
    color: $black;
    @media (max-width: 1700px) {
      font-size: 20px;
      line-height: 30px;
    }
    @media (max-width: 1024px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  &__date {
    //position: absolute;
    //left: 40px;
    //bottom: 40px;
    font-size: 14px;
    line-height: 21px;
    color: $gray;
    margin-top: auto;
    //display: flex;
    //justify-content: flex-end;
    //align-items: flex-end;
    //align-self: flex-end;
    @media (max-width: 1700px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  &__more {
    @include border-btn-gradient-green();
    padding: 13px 39px;
    display: flex;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
    width: max-content;
    margin-top: 30px;
  }
}
@keyframes triangle-top {
  0% {
    top: -400px;
  }
  50% {
    top: -450px;
  }
  100% {
    top: -400px;
  }
}