.faq {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 160px;
  position: relative;
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 100px;
    //transform: translateY(-50%);
    background-image: url("../../img/Desktop/Services/animate_icons/Group-3.png");
    background-repeat: no-repeat;
    width: 540px;
    height: 540px;
    animation: anim-3 6s infinite ease-in-out;
  }
  &__need-help {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 100px;
    h2 {
      font-size: 48px;
      line-height: 72px;
      font-family: $font-pb;
      margin-bottom: 80px;
      @include textGradientGreen();
      @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 50px;
      }
      span {
        @include textGradientPurple();
      }
    }
    a {
      @include bg-btn-gradient();
      display: block;
      padding: 15px 100px;
      font-size: 16px;
    }
  }
  &__animate-1{
    position: absolute;
    top: 100px;
    left: 145px;
    animation: anim-1 9s infinite ease-in-out;
    width: auto;
    height: auto;
     @media(max-width: 1700px) {
      left: 0;
    }
    @media(max-width: 1300px) {
      display: none;
      width: 300px;
    }

    img {
      width: 100%;
      height: 100%;
      @media(max-width: 1600px) {
        width: 70%;
      }
    }
  }
  &__animate-2 {
    position: absolute;
    top: 30px;
    right: 0;
    animation: anim-2 6s infinite ease-in-out;
  }
}

.faq-partner {
  background: rgb(19, 19, 28);

  &:before {
    background-image: url("../../img/partners/Services/tr.svg");
  }
  .accordion {
    background: rgb(19, 19, 28);
    color: #fff;
    &__header {
      background: #181824;
      border: 1px solid #1F1F2F;

      h2 {
        color: #fff;
      }
    }

    &__wrapper {
      background: rgb(19, 19, 28);
    }
  }
}

@keyframes anim-1 {
  0% {
    top: -210px;
  }
  50% {
    top: -250px;
  }
  100% {
    top: -210px;
  }
}
@keyframes anim-2 {
  0% {
    top: 20px;
  }
  50% {
    top: -20px;
  }
  100% {
    top: 20px;
  }
}
@keyframes anim-3 {
  0% {
    top: 100px;
  }
  50% {
    top: 150px;
  }
  100% {
    top: 100px;
  }
}