.join-team {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  background-color: #fff;
  padding: 80px;
  box-shadow: 0px 10px 20px -5px rgba(80, 0, 255, 0.15);
  border-radius: 20px;
  display: none;
  @media (max-width: 1280px) {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 20px;
  }
  &__thank {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    //display: flex;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    h2 {
      @include textGradientPurple();
      font-size: 48px;
      line-height: 72px;
      font-family: $font-pb;
      span {
        @include textGradientGreen()
      }
      @media (max-width: 480px) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    p {
      font-size: 24px;
      line-height: 36px;
      color: $gray;
      @media (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 50px;
    @media (max-width: 1280px) {
      margin-bottom: 30px;
    }
    span {
      @include textGradientPurple();
      @include title();
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        margin-bottom: 0;
      }
    }
    h2 {
      @include textGradientGreen();
      @include title();
      @media (max-width: 1280px) {
        margin-bottom: 0;
      }
    }
  }
  .closed-btn {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    outline: none;
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 5;
  }
}
.form-join {

  display: flex;
  flex-direction: column;
  @media (max-width: 1280px) {
    width: 100%;
  }
  &__inner {
    display: flex;
    @media (max-width: 1280px) {
      flex-direction: column;
      width: 100%;
    }
  }
  &__wrapper {
    &:first-child {
      margin-right: 50px;
      @media (max-width: 1280px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
  .form-send__input {
    margin-bottom: 40px;
    @media (max-width: 1280px) {
      width: 100%;
      margin-bottom: 25px;
    }
    label {
      font-size: 16px;
      line-height: 24px;
    }
    input {
      max-width: 530px;
      width: 530px;
      height: 80px;
      @media (max-width: 1280px) {
        width: 100%;
        height: 60px;
      }
      @media (max-width: 1280px) {
        max-width: 100%;
      }
    }
    span {
      display: none;
    }
    textarea {
      width: 100%;
      height: 150px;
      background: #FCFCFE;
      box-shadow: inset 2px 0px 15px -8px rgba(80, 0, 255, 0.25), inset 0px 2px 10px -5px rgba(80, 0, 255, 0.25);
      border-radius: 10px;
      border: none;
      outline: none;
      padding: 30px;
      //position: relative;
      &:focus ~ label {
        top: 0;
        font-size: 16px;
        line-height: 24px;
        @media (max-width: 1700px) {
          font-size: 14px;
          line-height: 21px;
        }
        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 15px;
        }
      }
    }
    &--area {
      label {
        top: 30px;
      }
    }
    &--error {
      input {
        border: 1px solid #EB5757;
      }
      span {
        position: absolute;
        bottom: -25px;
        right: 0;
        color: #EB5757;
        font-size: 14px;
        line-height: 21px;
        display: block;
      }
    }
  }
  &__block {
    margin-top: -5px;
    @media (max-width: 1280px) {
      margin-bottom: 25px;
    }
    span {
      font-size: 16px;
      line-height: 24px;
      color: #9AA4E2;
    }
  }
  &__add-file {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      background: linear-gradient(270deg, #3900B6 0%, #8F5BFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: $purple;
      margin-left: 15px;
    }
    .remove-file {
      border: 1px solid #000;
      border-radius: 100%;
      margin-left: 10px;
      font-size: 18px;
      background: #000;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: #000;
      width: 30px;
      height: 30px;
      &:hover {
        background: $linear-dark-purple-l;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $purple;
        border: 1px solid $purple;
      }
    }
  }
  &__btn {
    @include bg-btn-gradient();
    border: none;
    outline: none;
    margin: 0 auto;
  }
  &__label {
    margin-bottom: 8px;
    display: block;
  }
}