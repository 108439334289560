.about {
  position: relative;
  overflow: hidden;
  transition: linear;
  -webkit-transform: translateZ(1px);
  -moz-transform: translateZ(1px);
  -o-transform: translateZ(1px);
  transform: translateZ(1px);
  &__bar {
    height: 600px;
    width: 1px;
    background: #ced5ff;
    position: absolute;
    top: 300px;
    right: 70px;
    @media (max-width: 1700px) {
      height: 480px;
      top: 180px;
    }
    @media (max-width: 1366px) {
      height: 380px;
      top: 280px;
      right: 40px;
    }
    @media (max-width: 1280px) {
      display: none;
    }
  }
  &__indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    background: #5000ff;
  }
  &--showShadow {
    opacity: 1;
  }
  &__title {
    text-align: center;
    margin-bottom: 155px;
    margin-top: 30px;
    @media (max-width: 1024px) {
      margin-bottom: 0;
    }
    span {
      @include textGradientPurple();
      @include title();
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
    }
    h2 {
      @include textGradientGreen();
      @include title();
    }
    &--partners {
      span {
        -webkit-text-fill-color: aliceblue;
        color: #fff;
      }
    }
  }
  &__items {
    position: relative;
    height: 484px;
    @media (max-width: 1280px) {
      height: 100%;
    }
    &--partners {
      .about__desc {
        color: #fff;
      }
      .about__btn {
        background: $dark;
      }
    }
  }
  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @media (max-width: 1280px) {
      position: unset;
    }
  }
  &__wrapper {
    display: flex;
    //margin-bottom: 233px;
    height: 584px;
    @media (max-width: 1700px) {
      height: 450px;
    }
    @media (max-width: 1280px) {
      //flex-direction: column-reverse;
      height: 100%;
      //margin-bottom: 50px;
    }
  }
  &__decor {
    width: 50%;
    background: url("../../img/Desktop/about/Rectangle.svg");
    background-repeat: no-repeat;
    background-size: 660px 484px;
    position: relative;
    //overflow: hidden;
    //display: flex;
    //padding-bottom: 60%;
    @media (max-width: 1700px) {
      background-size: contain;
      background: url("../../img/Desktop/about/Rectangle1440.svg");
      background-repeat: no-repeat;
    }
    @media (max-width: 1366px) {
      left: -50px;
    }
    @media (max-width: 1280px) {
      display: flex;
      flex-direction: column;
      background: unset;
      left: -150px;
      // width: 61%;
      width: 100%;
    }
    @media (max-width: 1024px) {
      // display: flex;
      // flex-direction: column;
      // background: unset;
      left: 0;
      // width: 100%;
    }
    //@media (max-width: 580px) {
    //  background: url("../../img/Desktop/about/Mob/Rectangle-mob.svg");
    //  background-repeat: no-repeat;
    //  //background-size: cover;
    //  width: 100%;
    //  height: 100%;
    //}
  }
  // .about__mobil-1 {
  //   @media (max-width: 1280px) {
  //     left: 0;
  //     width: 100%;
  //   }
  // }
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin-right: 50px;
    @media (max-width: 1280px) {
      position: relative;
      height: 500px;
      background-size: contain;
      background: url("../../img/Desktop/about/Rectangle1440.svg");
      background-repeat: no-repeat;
    }
    @media (max-width: 1024px) {
      left: -50px;
      height: 380px;
    }
    @media (max-width: 768px) {
      left: -150px;
    }
    @media (max-width: 420px) {
      height: 330px;
    }
    @media (max-width: 380px) {
      height: 300px;
    }
    @media (max-width: 350px) {
      height: 270px;
    }
    &.about__inner-1 {
      @media (max-width: 580px) {
        background: url("../../img/Desktop/about/Group-mob.svg");
        background-repeat: no-repeat;
        left: -20px;
        background-size: contain;
      }
    }
    &.about__inner-2 {
      @media (max-width: 580px) {
        background: url("../../img/Desktop/about/Group-mob-2.svg");
        background-repeat: no-repeat;
        left: -20px;
        background-size: contain;
      }
    }
    &.about__inner-3 {
      @media (max-width: 580px) {
        background: url("../../img/Desktop/about/Group-mob-3.svg");
        background-repeat: no-repeat;
        left: -20px;
        background-size: contain;
      }
    }
    .about__vector-4 {
      @media (max-width: 1024px) {
        left: 70px;
      }
    }
    .about__vector-1,
    .about__vector-2,
    .about__vector-3,
    .about__vector-4,
    .about__vector-5 {
      @media (max-width: 1024px) {
        left: 200px;
      }
      @media (max-width: 580px) {
        display: none;
      }
    }
    &-2 {
      .about__vector-1 {
        top: -80px;
        left: 420px;
        img {
          @media (max-width: 1700px) {
            width: 100%;
            height: 100%;
          }
        }
      }
      .about__vector-2 {
        z-index: 1;
      }
      .about__vector-3 {
        top: 143px;
        left: 580px;
      }
      .about__vector-4 {
        top: 190px;
        left: 200px;
        z-index: 1;
      }
      .about__vector-5 {
        top: 0;
        left: 300px;
      }
    }
    &-3 {
      .about__vector-1 {
        top: 0;
        left: 220px;
      }
      .about__vector-2 {
        z-index: 2;
        top: 130px;
        left: 360px;
      }
      .about__vector-3 {
        top: 280px;
        left: 830px;
        @media (max-width: 1700px) {
          top: 180px;
          left: 630px;
        }
      }
      .about__vector-4 {
        top: 0;
        left: 400px;
        z-index: 1;
        @media (max-width: 1700px) {
          left: 300px;
        }
        @media (max-width: 1024px) {
          top: 70px;
        }
        img {
          @media (max-width: 1700px) {
            width: 80%;
            height: 80%;
          }
        }
      }
      .about__vector-5 {
        top: 0;
        left: 600px;
        @media (max-width: 1700px) {
          left: 450px;
        }
        img {
          @media (max-width: 1700px) {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  &__desc {
    position: sticky;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 200px;
    color: $black;
    @media (max-width: 1700px) {
      margin-left: 100px;
    }
    @media (max-width: 1366px) {
      margin-left: 0;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
    &.about__mobil-1,
    &.about__mobil-2,
    &.about__mobil-3 {
      @media (max-width: 1366px) {
        margin-left: 150px;
      }
      @media (max-width: 1024px) {
        margin-left: 0;
      }
    }
    &--mob {
      @media (max-width: 1024px) {
        display: none;
      }
    }
    h3 {
      font-family: $font-pb;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 30px;
      font-weight: bold;
      @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      }
    }
    p {
      width: 550px;
      font-size: 18px;
      line-height: 36px;
      margin-bottom: 40px;
      font-weight: 400 !important;
      @media (max-width: 1700px) {
        font-size: 16px;
        line-height: 32px;
      }
      @media (max-width: 1280px) {
        width: 100%;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }
  &__animate-text {
    position: absolute;
    @media (max-width: 1280px) {
      position: unset;
      padding-right: 20px;
      padding-left: 20px;
      height: 500px;
      padding-top: 80px;
    }
    @media (max-width: 1024px) {
      // padding-right: 20px;
      // padding-left: 20px;
      height: 100%;
      padding-bottom: 50px;
    }
    @media (max-width: 580px) {
      padding-top: 0;
    }
  }
  &__btn {
    @include border-btn-gradient-green();
    padding: 13px 39px;
    font-size: 16px;
    line-height: 24px;
    width: max-content;
    @media (max-width: 480px) {
      font-size: 14px;
      padding: 10px 30px;
    }
    a {
      &:hover {
        color: $light-green;
      }
    }
  }
  &__vector-1 {
    position: absolute;
    top: -30px;
    left: 201px;
    animation: float-1 4s ease-in-out infinite;
    @media (max-width: 1700px) {
      left: 260px;
    }
    @media (max-width: 580px) {
      left: 130px;
    }
    img {
      @media (max-width: 1700px) {
        width: 60%;
        height: 70%;
      }
    }
  }
  &__vector-2 {
    position: absolute;
    top: 297px;
    left: 320px;
    animation: float-2 4s ease-out infinite;
    @media (max-width: 1700px) {
      top: 240px;
      left: 350px;
    }
    @media (max-width: 580px) {
      top: 170px;
      left: 170px;
    }
    img {
      @media (max-width: 1700px) {
        width: 90%;
        height: 90%;
      }
      @media (max-width: 580px) {
        width: 60%;
        height: 90%;
      }
    }
  }
  &__vector-3 {
    position: absolute;
    top: 320px;
    left: 688px;
    animation: float-3 5s ease-out infinite;
    @media (max-width: 1700px) {
      top: 280px;
      left: 530px;
    }
    @media (max-width: 580px) {
      top: 190px;
      left: 390px;
    }
    img {
      @media (max-width: 1700px) {
        width: 90%;
        height: 90%;
      }
      @media (max-width: 580px) {
        width: 70%;
        height: 90%;
      }
    }
  }
  &__vector-4 {
    position: absolute;
    top: 63px;
    left: 333px;
    animation: float-4 10s ease-out infinite;
    @media (max-width: 1700px) {
      left: 340px;
      top: 115px;
    }
    @media (max-width: 580px) {
      left: 180px;
      top: 50px;
    }
    img {
      @media (max-width: 1700px) {
        width: 80%;
        height: 90%;
        object-fit: contain;
      }
      @media (max-width: 1700px) {
        width: 60%;
      }
    }
  }
  &__vector-5 {
    position: absolute;
    top: -42px;
    left: 477px;
    animation: float-5 5s ease-out infinite;
    @media (max-width: 1700px) {
      top: 60px;
      left: 380px;
    }
    @media (max-width: 580px) {
      top: 0;
      left: 300px;
    }
    img {
      @media (max-width: 1700px) {
        width: 90%;
        height: 90%;
      }
    }
  }
}
#about {
  //padding-top: 100px!important;
  box-shadow: 0 -36px 61px 10px rgba(255, 255, 255, 0.9);
  background: #fff;
  //top: 62.2969px;
}
#about-partners {
  //background-image: url("../../img/partners/about-bg.png");
  //background-repeat: no-repeat;
}
@keyframes float-1 {
  50% {
    transform: translate(0, 20px);
    //transform: rotate3d(1, 1, 1, 45deg);
  }
}
@keyframes float-2 {
  50% {
    transform: translate(0, 30px);
  }
}
@keyframes float-3 {
  50% {
    transform: translate(25px, 0px);
  }
}
@keyframes float-4 {
  50% {
    transform: translate(35px, 0px);
  }
}
@keyframes float-5 {
  50% {
    transform: translate(0, 50px);
  }
}
.about__item--1 {
  //z-index: 1;
  .about__inner {
    //animation: animSlide 1s linear infinite;
    //animation-duration: 100s;
    //animation-timing-function: linear;
    ////animation-delay: -39.68s;
    //animation-iteration-count: 1;
    //animation-direction: normal;
    //animation-fill-mode: forwards;
    //animation-play-state: paused;
    //animation-name: animSlide;
  }
}
//.about__item--activeN {
//
//  .about__inner {
//    opacity: 1;
//    z-index: 3;
//    animation: animSlideN 1s linear;
//  }
//}
//.about__item--activeP {
//
//  .about__inner {
//    opacity: 1;
//    z-index: 3;
//    animation: animSlideP 1s linear;
//  }
//}
@keyframes animSlideN {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  50% {
    transform: translateX(-100px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes animSlideP {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  50% {
    transform: translateX(100px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
