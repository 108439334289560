.accordion {
  padding-bottom: 200px;
  background: #fff;
  @media (max-width: 1700px) {
    padding-bottom: 100px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }
  &__container {
    @include container();
    padding-right: 320px;
    padding-left: 320px;
    @media (max-width: 1700px) {
      padding-right: 80px;
    }
    @media (max-width: 1024px) {
      padding-right: 20px;
    }
  }
  &__title {
    font-size: 48px;
    line-height: 72px;
    font-family: $font-pb;
    text-align: center;
    margin-bottom: 80px;
    @include textGradientPurple();
    span {
      @include textGradientGreen();
    }
    @media (max-width: 1024px) {
      font-size: 28px;
      line-height: 32px;
    }
  }
  &__item {
    margin-bottom: 30px;
    position: relative;
    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }
  }
  &__header {
    //border-bottom: 1px solid $gray-color;
    display: flex;
    position: relative;
    padding: 22px 30px;
    align-items: center;
    box-shadow: 0px 10px 20px -5px rgba(80, 0, 255, 0.15);
    border-radius: 10px;
    border: 1px solid #CED5FF;
    background: #fff;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 36px;
      position: relative;
      width: 100%;
      color: $black;
      padding-right: 30px;
      &:after {
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: url("../../img/Desktop/Icon/Vector.svg");
        background-repeat: no-repeat;
        width: 25px;
        height: 20px;
        transition: 1s ease-in-out;
        transform: rotate(180deg);
      }
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
    &--active {
      h2 {
        @include textGradientGreen();
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &__content {
    display: none;
  }
  &__wrapper {
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1366px) {
      padding-left: 20px;
    }
    @media (max-width: 1024px) {
      padding-top: 20px;
      padding-bottom: 0;
    }
  }
  &__text {
    b {
      font-size: 20px;
      line-height: 36px;
      font-family: $font-psb;
      display: block;
      margin-bottom: 40px;
      @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 27px;
      }
    }
    p {
      margin-bottom: 40px;
    }
    ul {
      margin-bottom: 40px;
      li {
        position: relative;
        padding-left: 20px;
        &:before {
          content: "";
          position: absolute;
          top: 14px;
          left: 0;
          background: $linear-green-dark;
          width: 5px;
          height: 5px;
          border-radius: 100%;
        }
      }
    }
  }
  &__btn {
    @include bg-btn-gradient();
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border: none;
    outline: none;
  }
}