.contact {
  padding-top: 200px;
  @media (max-width: 768px) {
    padding-top: 110px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../../img/setka-contact.png);
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: 10;
    @media (max-width: 768px) {
      background-image: url(../../img/bg.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0 0;
    }
  }
  &--path {
    background: $dark;
    .contact__title {
      -webkit-text-fill-color: aliceblue;
      color: #fff;
    }
    .contact__desc span {
      color: #fff;
    }
    .contact__items:before {
      background-image: url("../../img/Desktop/Group-b.png");
    }
    .contact__items:after {
      background-image: url("../../img/Desktop/Group-1-b.png");
    }
    .contact__desc a {
      color: #fff;
    }
    .contact__inner {
      background: #18182C;
    }
  }
  &__title {
    font-size: 76px;
    line-height: 100px;
    font-family: $font-pb;
    @include textGradientPurple();
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 1024px) {
      font-size: 28px;
      line-height: 32px;
    }
  }
  &__text {
    font-size: 20px;
    line-height: 42px;
    text-align: center;
    color: $gray;
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  &__items {
    margin-top: 165px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    max-width: 1920px;
    padding-left: 168px;
    padding-right: 168px;
    width: 100%;
    z-index: 120;

    @media (max-width: 1700px) {
      padding-left: 80px;
      padding-right: 80px;
    }
    @media (max-width: 1280px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 80px;
    }
    &:before {
      content: "";
      position: absolute;
      top: -500px;
      left: 0;
      width: 512px;
      height: 533px;
      background-image: url("../../img/Desktop/Group.png");
      background-repeat: no-repeat;
      z-index: -1;
      animation: contact-anim 4s infinite;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 100px;
      right: 0;
      width: 512px;
      height: 533px;
      background-image: url("../../img/Desktop/Group-1.png");
      background-repeat: no-repeat;
      z-index: -1;
      animation: contact-anim 4s infinite;
      @media (max-width: 1280px) {
        display: none;
      }
    }
  }

  &__inner {
    width:calc( ( 100% - 60px ) / 3 );
    margin-bottom: 30px;
    margin-right: 30px;
    background-color: #fff;
    box-shadow: 0 10px 20px -5px rgba(80,0,255,.15);
    border-radius: 20px;
    padding: 20px;

    &:nth-child(3n) {
      margin-right: 0;
      @media (max-width: 1024px) {
        margin-right: 20px;
      }
    }

    &:nth-child(2n) {
      @media (max-width: 1024px) {
        margin-right: 0px;
      }
    }

    @media (max-width: 1280px) {
      width:calc( ( 100% - 40px ) / 3 );
      margin-bottom: 20px;
      margin-right: 20px;
    }

    @media (max-width: 1024px) {
      width:calc( ( 100% - 40px ) / 2 );
      margin-bottom: 20px;
      margin-right: 20px;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }

  &__item {
    display: flex;
    margin-bottom: 30px;

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }

  &__icon {
    margin-right: 20px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  &__desc {
    display: flex;
    flex-direction: column;
    a {
      font-size: 18px;
      line-height: 24px;
      font-family: $font-pl;
      color: #000;
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    span {
      font-size: 18px;
      line-height: 24px;
      font-family: $font-pl;
      color: #000;
      text-align: left;
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.map {
  position: relative;
  z-index: 2;
  height: 840px;
  bottom: -400px;
  margin-top: -200px;
}
.contact-page {
  background-color: $dark;
}
@keyframes contact-anim {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 30px);
  }
  100% {
    transform: translate(0, 0);
  }
}