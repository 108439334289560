@mixin border-btn-gradient() {
  position: relative;
  padding: 8px 20px;
  width: max-content;
  height: fit-content;
  color: $dart-green;
  border-radius: 50px;
  background-clip: padding-box;
  background-color: #fff;
  line-height: 18px;
  font-size: 12px;
  cursor: pointer;
  @media (min-width: 1280px) {
    padding: 10px 30px;
    font-size: 15px;
  }
  @media (min-width: 1700px) {
    padding: 13px 39px;
    font-size: 16px;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border-radius: 50px;
    border: 1px solid #049c7c;
    //background: $linear-green-dark;
  }
  &:hover {
    &:after {
      background: $linear-green;
    }
  }
  &:active {
    &:after {
      background: $linear-green;
    }
  }
}
@mixin bg-btn-gradient() {
  position: relative;
  width: max-content;
  height: fit-content;
  color: $white;
  border-radius: 50px;
  background-clip: padding-box;
  background: $linear-green-dark;
  line-height: 18px;
  font-size: 14px;
  cursor: pointer;
  padding: 13px 48px;
  @media (max-width: 1700px) {
    padding: 10px 30px;
  }
  &:hover {
    background: $linear-green;
  }
}
@mixin border-btn-white() {
  position: relative;
  padding: 10px 0px;
  width: 174px;
  height: fit-content;
  color: $white;
  border-radius: 50px;
  background-clip: padding-box;
  line-height: 22px;
  font-size: 15px;
  cursor: pointer;
  border: 2px solid $white;
  display: flex;
  justify-content: center;
  &:hover {
    background: $linear-green;
  }
}
@mixin border-btn-gradient-green() {
  position: relative;
  color: $dart-green;
  border-radius: 50px;
  background-clip: padding-box;
  background-color: #fff;
  cursor: pointer;;
  &:after {
    content: '';
    position: absolute;
    z-index: -10;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border-radius: 50px;
    background: linear-gradient(90deg, #049C7C 0%, #026A53 99.99%);
    //background: $linear-green-dark;
  }
  &:hover {
    color: $light-green;
    &:after {
      background: $linear-green;
    }
  }
  &:active {
    &:after {
      background: $linear-green;
    }
  }
}
@mixin border-btn-gradient-purple() {
  position: relative;
  color: $purple;
  border-radius: 50px;
  background-clip: padding-box;
  background-color: #fff;
  cursor: pointer;
  height: max-content;
  width: max-content;
  &:after {
    content: '';
    position: absolute;
    z-index: -10;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border-radius: 50px;
    background: $linear-dark-purple-l;
    //background: $linear-green-dark;
  }
  &:hover {
    &:after {
      background: $linear-dark-purple-l;
    }
  }
  &:active {
    &:after {
      background: $linear-dark-purple-l;
    }
  }
}