.service {
  //background: #fff;
  padding-top: 120px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../img/Services/set.svg");
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  @media (max-width: 1700px) {
    padding-top: 80px;
  }
  @media (max-width: 1024px) {
    padding-top: 60px;
  }
  &__header {
    width: 100%;
    height: 939px;
    //background: #1b3548;
    background-image: url("../../img/Desktop/Services/bg.svg");
    background-repeat: no-repeat;
    position: relative;
    @media (max-width: 1440px) {
      //background-image: url("../../img/Desktop/Services/bg-note.svg");
      height: 704px;
      background-size: cover;
    }
    @media (max-width: 1024px) {
      background-size: 1024px;
      height: 508px;
    }
    @media (max-width: 480px) {
      background-image: url("../../img/Desktop/Services/bg-mob.svg");
      background-size: 320px;
    }
    &--bank {
      clip-path: polygon(100% 97%,-28% 71%,0 0,100% 0);
      @media (max-width: 1700px) {
        clip-path: none;
      }
    }
    &--partners {
      background-image: url("../../img/partners/bg.svg");
    }
  }
  &__image-l {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 11;
    @media (max-width: 768px) {
      top: -100px;
    }
    &--real {
      top: 150px;
      @media (max-width: 768px) {
        top: -80px;
      }
    }
    &--cross {
      top: unset;
      bottom: 0;
      @media (max-width: 768px) {
        top: -50px;
      }
    }
    &--issuance {
      top: -45px;
      left: 50px;
    }
    &--top {
      top: -80px;
    }
    &--card {
      top: unset;
      bottom: 150px;
      @media (max-width: 768px) {
        top: -50px;
        bottom: unset;
      }
    }
    img {
      @media (max-width: 1700px) {
        width: 200px;
      }
      @media (max-width: 1024px) {
        width: 100px;
      }
    }
  }
  &__image-r {
    position: absolute;
    top: -70px;
    right: 0;
    animation: service-anim 4s infinite;
    z-index: 11;
    @media (max-width: 768px) {
      top: unset;
      bottom: 0;
    }
    img {
      @media (max-width: 1700px) {
        width: 350px;
      }
      @media (max-width: 1024px) {
        width: 170px;
      }
    }
    &--bank {
      top: 60px;
      @media (max-width: 768px) {
        top: unset;
        bottom: 0;
      }
    }
  }
  &__inner {
    position: relative;
    margin-left: 323px;
    padding-top: 220px;
    z-index: 12;
    @media (max-width: 1700px) {
      margin-left: 238px;
      padding-top: 130px;
    }
    @media (max-width: 1024px) {
      margin-left: 100px;
      padding-top: 80px;
    }
    @media (max-width: 768px) {
      margin-left: 80px;
    }
    @media (max-width: 400px) {
      margin-left: 20px;
    }
    h1 {
      font-size: 76px;
      line-height: 100px;
      font-family: $font-pb;
      margin-bottom: 40px;
      @include textGradientPurple();
      @media (max-width: 1700px) {
        font-size: 62px;
        line-height: 93px;
      }
      @media (max-width: 1366px) {
        font-size: 38px;
        line-height: 44px;
      }
      @media (max-width: 768px) {
        font-size: 28px;
        line-height: 32px;
      }
    }
    p {
      width: 760px;
      font-size: 24px;
      line-height: 42px;
      @include textGradientPurple();
      @media (max-width: 1700px) {
        font-size: 20px;
        line-height: 36px;
      }
      @media (max-width: 1366px) {
        font-size: 16px;
        line-height: 24px;
        width: 530px;
      }
      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 25px;
        width: 90%;
      }
    }
    a {
      margin-top: 50px;
      display: block;
      @include border-btn-gradient-green();
      background-color: rgb(232,235,255);
      padding: 15px 60px;
      width: max-content;
      font-size: 18px;
      line-height: 27px;
      @media (max-width: 768px) {
        padding: 10px 30px;
        font-size: 12px;
        line-height: 18px;
      }
    }
    &--partners {
      h1 {
        color: #fff;
        -webkit-text-fill-color: unset;
      }
      p {
        color: #fff;
        -webkit-text-fill-color: unset;
      }
      a {
        background: $linear-dark-purple;
      }
    }
  }
}
.body {
  //background-color: #fff;
  padding-top: 170px;
  padding-bottom: 160px;
  position: relative;
  @media (max-width: 1024px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  &:before {
    content: "";
    position: absolute;
    top: -245px;
    right: 0px;
    background-image: url(../../img/Desktop/Services/icon2/tr.svg);
    background-repeat: no-repeat;
    width: 717px;
    height: 1067px;
    animation: service-anim 6s infinite;
    @media (max-width: 1700px) {
      background-size: 85%;
    }
    @media (max-width: 1280px) {
      display: none;
    }
  }
  &--partners {

    &:before {
      background-image: url(../../img/partners/Services/tr.svg);
      background-repeat: no-repeat;
    }
  }
  &__container {
    padding-left: 120px;
    padding-right: 160px;
    display: flex;
    @media (max-width: 1700px) {
      padding-left: 100px;
      padding-right: 120px;
    }
    @media (max-width: 1280px) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
    }
  }
  &__images {
    width: 765px;
    height: 504px;
    position: relative;
    //background-color: #1b3548;
    margin-right: 100px;
    @media (max-width: 1700px) {
      width: 600px;
      margin-right: 30px;
    }
    @media (max-width: 1024px) {
      margin-right: 0px;
    }
    @media (max-width: 768px) {
      width: 320px;
      height: 200px;
      margin-top: 20px;
    }
    &--real {
      @media (max-width: 1024px) {
        margin-top: 50px;
      }
      .body__vector-3 {
        left: 160px;
        @media (max-width: 768px) {
          left: 70px;
          top: 30px;
        }
      }
      .body__vector-1 {
        right: 70px;
        bottom: 90px;
        z-index: 1;
        @media (max-width: 1700px) {
          right: 0;
        }
        @media (max-width: 768px) {
          right: 0;
          bottom: 0;
        }
        img {
          @media (max-width: 1700px) {
            width: 280px;
          }
          @media (max-width: 768px) {
            width: 130px;
          }
        }
      }
      .body__vector-4 {
        top: -200px;
        @media (max-width: 1700px) {
          width: 400px;
        }
        @media (max-width: 768px) {
          width: 100px;
          top: -80px;
          right: 60px;
        }
        img {
          @media (max-width: 768px) {
            width: 250px;
          }
        }
      }
      .body__vector-5 {
        right: 200px;
        @media (max-width: 1700px) {
          right: 60px;
        }
        img {
          @media (max-width: 1700px) {
            width: 200px;
          }
          @media (max-width: 768px) {
            width: 100px;
          }
        }
      }
      img {
        @media (max-width: 1700px) {
          width: 100%;
        }
      }
    }
    &--cross {
      .body__vector-2 {
        bottom: 0;
        @media (max-width: 1700px) {
          bottom: 100px;
        }
        @media (max-width: 768px) {
          bottom: 20px;
          left: 20px;
        }
        img {
          @media (max-width: 1700px) {
            width: 200px;
          }
          @media (max-width: 768px) {
            width: 100px;
          }
        }
      }
      .body__vector-1 {
        right: 110px;
        bottom: 150px;
        z-index: 1;
        @media (max-width: 1700px) {
          bottom: 200px;
        }
        @media (max-width: 768px) {
          bottom: 65px;
          right: 50px;
        }
        img {
          @media (max-width: 1700px) {
            width: 200px;
          }
          @media (max-width: 768px) {
            width: 100px;
          }
        }
      }
      .body__vector-3 {
        @media (max-width: 768px) {
          top: 20px;
          left: 60px;
        }
      }
      .body__vector-4 {
        right: -288px;
        top: 127px;
        @media (max-width: 1700px) {
          right: -120px;
        }
        @media (max-width: 768px) {
          right: 0;
          top: -60px;
        }
        img {
          @media (max-width: 768px) {
            width: 200px;
          }
        }
      }
    }
    &--issuance {
      .body__vector-1 {
        right: 130px;
        bottom: 35px;
        z-index: 1;
        @media (max-width: 1700px) {
          bottom: 45px;
        }
        @media (max-width: 768px) {
          right: 50px;
        }
        img {
          @media (max-width: 1700px) {
            width: 250px;
          }
          @media (max-width: 768px) {
            width: 100px;
          }
        }
      }
      .body__vector-2 {
        img {
          @media (max-width: 1700px) {
            width: 450px;
          }
          @media (max-width: 768px) {
            width: 300px;
          }
        }
      }
      .body__vector-3 {
        @media (max-width: 1700px) {
          left: 30px;
        }
        @media (max-width: 768px) {
          left: 55px;
        }
      }
      .body__vector-4 {
        right: unset;
        top: -160px;
        left: -204px;
        @media (max-width: 1700px) {
          top: -100px;
          left: -104px;
        }
        @media (max-width: 768px) {
          top: -20px;
          left: -20px;
        }
      }
    }
    &--faster {
      .body__vector-1 {
        right: 80px;
        z-index: 2;
        @media (max-width: 1700px) {
          right: 40px;
        }
        img {
          @media (max-width: 1700px) {
            width: 220px;
          }
          @media (max-width: 768px) {
            width: 100px;
          }
        }
      }
      .body__vector-2 {
        top: -40px;
        left: 60px;
        z-index: 1;
        @media (max-width: 1700px) {
          left: 120px;
        }
        @media (max-width: 768px) {
          left: 80px;
          top: -20px;
        }
      }
      .body__vector-3 {
        left: 180px;
        @media (max-width: 1700px) {
          left: 110px;
        }
        @media (max-width: 768px) {
          left: 70px;
        }
      }
      .body__vector-4 {
        right: 245px;
        @media (max-width: 768px) {
          right: 120px;
          top: -60px;
        }
      }
      .body__vector-5 {
        animation: service-anim 5s infinite;
        bottom: 0;
        left: 100px;
        @media (max-width: 1700px) {
          left: 40px;
        }
        img {
          @media (max-width: 1700px) {
            width: 220px;
          }
          @media (max-width: 768px) {
            width: 100px;
          }
        }
      }
    }
    &--swift{
      .body__vector-1 {
        right: -60px;
        bottom: 190px;
        z-index: 1;
        @media (max-width: 1700px) {
          right: 20px;
          bottom: 250px;
        }
        @media (max-width: 768px) {
          bottom: 50px;
        }
        img {
          @media (max-width: 1700px) {
            width: 260px;
          }
          @media (max-width: 768px) {
            width: 160px;
          }
        }
      }
      .body__vector-2 {
        left: 0;
        bottom: 0;
        @media (max-width: 1700px) {
          left: 50px;
          bottom: 120px;
        }
        @media (max-width: 768px) {
          bottom: 0;
          left: 20px;
        }
        img {
          @media (max-width: 1700px) {
            width: 260px;
          }
          @media (max-width: 768px) {
            width: 160px;
          }
        }
      }
      .body__vector-3 {
        top: 70px;
        left: 170px;
        @media (max-width: 768px) {
          left: 80px;
          top: 40px;
        }
        img {
          @media (max-width: 1700px) {
            width: 260px;
          }
          @media (max-width: 768px) {
            width: 160px;
          }
        }
      }
      .body__vector-4 {
        @media (max-width: 768px) {
          right: 80px;
          top: -20px;
        }
      }
    }
    &--cash{
      .body__vector-1 {
        right: 125px;
        bottom: 115px;
        z-index: 1;
        @media (max-width: 1700px) {
          right: 20px;
          bottom: 250px;
        }
        @media (max-width: 768px) {
          bottom: 30px;
          right: 40px;
        }
        img {
          @media (max-width: 1700px) {
            width: unset;
          }
          @media (max-width: 768px) {
            width: 60px;
          }
        }
      }
      .body__vector-2 {
        left: 40px;
        bottom: 155px;
        @media (max-width: 1700px) {
          left: 65px;
          bottom: 190px;
        }
        @media (max-width: 768px) {
          bottom: 15px;
          left: 0;
        }
        img {
          @media (max-width: 1700px) {
            width: 180px;
          }
          @media (max-width: 768px) {
            width: 120px;
          }
        }
      }
      .body__vector-3 {
        top: 100px;
        left: 200px;
        z-index: 1;
        @media (max-width: 768px) {
          left: 80px;
          top: 40px;
        }
        img {
          @media (max-width: 1700px) {
            width: 210px;
          }
          @media (max-width: 768px) {
            width: 130px;
          }
        }
      }
      .body__vector-4 {
        right: -120px;
        top: -110px;
        @media (max-width: 1700px) {
          right: -40px;
          top: -30px;
        }
        @media (max-width: 768px) {
          right: 10px;
          top: -20px;
        }
      }
      .body__vector-5 {
        top: -70px;
        left: 40px;
        @media (max-width: 1024px) {
          top: 0;
        }
        @media (max-width: 768px) {
          top: -20px;
          left: 10px;
        }
        img {
          @media (max-width: 1700px) {
            width: 300px;
          }
          @media (max-width: 768px) {
            width: 150px;
          }
        }
      }
    }
    &--bank {
      .body__vector-1 {
        right: -10px;
        bottom: 115px;
        z-index: 1;
        @media (max-width: 1700px) {
          right: 0px;
          bottom: 180px;
        }
        @media (max-width: 768px) {
          bottom: -7px;
        }
        img {
          @media (max-width: 1700px) {
            width: 260px;
          }
          @media (max-width: 768px) {
            width: 160px;
          }
        }
      }
      .body__vector-2 {
        left: 0;
        bottom: 0;
        @media (max-width: 1700px) {
          left: 50px;
          bottom: 120px;
        }
        @media (max-width: 768px) {
          bottom: 0;
          left: 20px;
        }
        img {
          @media (max-width: 1700px) {
            width: 260px;
          }
          @media (max-width: 768px) {
            width: 160px;
          }
        }
      }
      .body__vector-3 {
        top: 70px;
        left: 170px;
        z-index: 1;
        @media (max-width: 768px) {
          left: 80px;
          top: 40px;
        }
        img {
          @media (max-width: 1700px) {
            width: 260px;
          }
          @media (max-width: 768px) {
            width: 160px;
          }
        }
      }
      .body__vector-4 {
        right: 60px;
        @media (max-width: 1700px) {
          right: 0px;
          top: -120px;
        }
        @media (max-width: 768px) {
          top: -60px;
        }
      }
    }
    &--card {
      .body__vector-1 {
        right: 70px;
        bottom: 30px;
        z-index: 1;
        @media (max-width: 1700px) {
          right: 150px;
          bottom: 180px;
        }
        @media (max-width: 768px) {
          bottom: -7px;
          right: 90px;
        }
        img {
          @media (max-width: 1700px) {
            width: 260px;
          }
          @media (max-width: 768px) {
            width: 160px;
          }
        }
      }
      .body__vector-2 {
        left: 190px;
        bottom: -30px;
        z-index: 2;
        @media (max-width: 1700px) {
          left: 190px;
          bottom: 191px;
        }
        @media (max-width: 768px) {
          bottom: 12px;
          left: 65px;
        }
        img {
          @media (max-width: 1700px) {
            width: 260px;
          }
          @media (max-width: 768px) {
            width: 160px;
          }
        }
      }
      //.body__vector-3 {
      //  top: 70px;
      //  left:  170px;
      //  z-index: 1;
      //  @media (max-width: 768px) {
      //    left: 80px;
      //    top: 40px;
      //  }
      //  img {
      //    @media (max-width: 1700px) {
      //      width: 260px;
      //    }
      //    @media (max-width: 768px) {
      //      width: 160px;
      //    }
      //  }
      //}
      .body__vector-4 {
        right: unset;
        left: 0;
        top: 0;
        @media (max-width: 1700px) {
          //right: 0px;
          //top: -120px;
        }
        @media (max-width: 768px) {
          top: 60px;
        }
      }
    }
  }
  &__content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @media (max-width: 1024px) {
      width: 100%;
    }
    p {
      font-size: 18px;
      line-height: 36px;
      margin-bottom: 40px;
      @media (max-width: 1700px) {
        font-size: 16px;
        line-height: 36px;
      }
      @media (max-width: 1280px) {
        font-size: 14px;
        line-height: 30px;
        margin-bottom: 20px;
      }
    }
    ul {
      margin-top: 10px;
      margin-left: 10px;
      li {
        position: relative;
        padding-left: 20px;
        line-height: 46px;
        @media (max-width: 1024px) {
          line-height: 30px;
        }
        &:before {
          content: "";
          position: absolute;
          top: 14px;
          left: 0;
          background: $linear-green-dark;
          width: 7px;
          height: 7px;
          border-radius: 100%;
        }
      }
    }
    &--partners {
      color: #fff;
    }
  }
  &__vector-1,
  &__vector-2,
  &__vector-3,
  &__vector-4,
  &__vector-5 {
    position: absolute;
    @media (max-width: 1024px) {
      animation: unset!important;
    }
    img {
      @media (max-width: 1700px) {
        width: 350px;
      }
      @media (max-width: 768px) {
        width: 150px;
      }
    }
  }
  &__vector-1 {
    right: 0;
    bottom: 0;
    animation: service-anim 5s infinite;
  }
  &__vector-2 {
    animation: service-anim 7s infinite;
  }
  &__vector-3 {
    top: 50px;
    left: 90px;
    animation: service-anim 8s infinite;
    @media (max-width: 768px) {
      top: 0;
      left: 45px;
    }
    img {
      @media (max-width: 1700px) {
        width: 400px;
      }
      @media (max-width: 768px) {
        width: 190px;
      }
    }
  }
  &__vector-4 {
    right: -225px;
    top: -130px;
    animation: service-anim 3s infinite;
    @media (max-width: 1700px) {
      right: -100px;
      top: -60px;
    }
    @media (max-width: 768px) {
      right: 0;
      top: -50px;
    }
  }
  &__vector-5 {
    animation: service-anim 3s infinite;
  }
}
.simplify {
  //background-color: #fff;
  padding-bottom: 160px;
  &__container {
    margin: 0 162px;
    background: $linear-green;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    display: flex;
    position: relative;
    height: 444px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    //@media (max-width: 1700px) {
    //  border-top-right-radius: 90px;
    //  border-bottom-left-radius: 90px;
    //}
    //@media (max-width: 1366px) {
    //  border-top-right-radius: 70px;
    //  border-bottom-left-radius: 70px;
    //}
    @media (max-width: 768px) {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      overflow: unset;
    }
    &:after {
      //content: "";
      position: absolute;
      right: 0;
      top: 0;
      background-color: #1b3548;
      width: 100px;
      height: 100px;
      border-top-right-radius: 100px;
    }
    @media (max-width: 1700px) {
      margin: 0 122px;
      height: 386px;
    }
    @media (max-width: 1024px) {
      margin: 0 20px;
      height: 386px;
    }
    @media (max-width: 768px) {
      background: #fff;
    }
    &--partners {
      background: $linear-dark-purple;
    }
  }
  //&__wrap {
  //  position: relative;
  //  width: max-content;
  //
  //}
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
      color: $black;
    }
    span {
      font-size: 48px;
      line-height: 72px;
      font-family: $font-pb;
      @media (max-width: 1366px) {
        font-size: 36px;
        line-height: 44px;
      }
      @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 32px;
      }
      @media (max-width: 768px) {
        @include textGradientPurple();
        margin: 0 80px;
        text-align: center;
      }
      @media (max-width: 430px) {
        margin: 0 20px;
      }
      b {
        @media (max-width: 768px) {
          @include textGradientGreen();
        }
      }
    }
    p {
      font-size: 18px;
      line-height: 36px;
      margin-top: 40px;
      width: 700px;
      text-align: center;
      @media (max-width: 1700px) {
        font-size: 16px;
        line-height: 32px;
        width: 650px;
      }
      @media (max-width: 1366px) {
        margin-top: 30px;
        width: 450px;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 30px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    a {
      margin-top: 50px;
      background-color: #fff;
      padding: 13px 48px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      border-radius: 100px;
      @media (max-width: 1700px) {
        margin-top: 40px;
      }
      @media (max-width: 1366px) {
        font-size: 14px;
        line-height: 18px;
        padding: 10px 30px;
        margin-top: 30px;
      }
      @media (max-width: 768px) {
        @include bg-btn-gradient();
      }
    }
  }
  &__image-l {
    position: absolute;
    left: 0;
    @media (max-width: 768px) {
      left: -20px;
      bottom: -100px;
    }
    &--faster {
      bottom: -11px;
      @media (max-width: 768px) {
        bottom: -100px;
      }
    }
    img {
      @media (max-width: 1700px) {
        width: 200px;
      }
      @media (max-width: 1366px) {
        width: 150px;
      }
      @media (max-width: 768px) {
        width: unset;
      }
    }
    &--bank {
      @media (max-width: 1700px) {
        left: 50%;
        transform: translateX(-50%);
      }
      @media (max-width: 1024px) {
        left: -20px;
        bottom: -100px;
        transform: unset;
      }
      img {
        @media (max-width: 1700px) {
          width: 1390px;
        }
        @media (max-width: 1024px) {
          width: unset;
        }
      }
    }
  }
  &__image-r {
    position: absolute;
    right: -5px;
    top: 0;

    @media (max-width: 1700px) {
      width: 100%;
      height: 100%;
    }
    //@media (max-width: 1366px) {
    //  right: -90px;
    //}
    //@media (max-width: 1024px) {
    //  right: -120px;
    //}
    @media (max-width: 768px) {
      right: -20px;
      top: -40px;
      width: unset;
      height: unset;
    }
    img {
      @media (max-width: 1700px) {
        position: absolute;
        right: 0;
        width: auto;
        height: 80%;
        object-fit: cover;
      }
      @media (max-width: 1170px) {
        height: 65%;
      }
      @media (max-width: 1024px) {
        height: 55%;
      }
      @media (max-width: 768px) {
        width: unset;
        height: unset;
        position: unset;
      }
    }
  }
}



@keyframes service-anim {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}