.guavapay-network {
  background: #fff;
  .news {
    padding-top: 140px;
    @media (max-width: 1024px) {
      padding-top: 80px;
    }
  }
}
.tags {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  position: sticky;
  align-items: center;
  b {
    @include textGradientPurple();
    font-size: 24px;
    line-height: 42px;
    margin-right: 30px;
    @media (max-width: 1024px) {
      font-size: 20px;
    }
  }
  a {
    @include border-btn-gradient-green();
    padding: 3px 10px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    height: fit-content;
    @media (max-width: 1024px) {
      font-size: 12px;
      line-height: 18px;
    }
    &:after {
      border-radius: 10px;
    }
  }
}