.track {
  background-color: $dark;
  padding-bottom: 100px;
  @media (max-width: 1280px){
    padding-right: 30px;
    padding-left: 30px;
  }
  &__title {
    text-align: center;
    margin-bottom: 80px;
    h2 {
      @include textGradientGreen();
      font-size: 48px;
      line-height: 72px;
      font-family: $font-pb;
      span {
        color: #fff;
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}


.form-track {
  position: relative;
  margin: 0 auto;
  z-index: 20;
  &__container {
    max-width: 1244px;
    width: 100%;
    height: 100%;
    background-color: #13131c;
    margin: 0 auto;
    padding: 60px 50px 70px;
    border: 1px solid #5000FF;
    border-radius: 20px;
    background-clip: padding-box;
    position: relative;
    @media (max-width: 1024px) {
      padding: 30px 10px 40px;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: -15;
      top: -15px;
      bottom: -15px;
      left: -15px;
      right: -15px;
      border-radius: 20px;
      background: linear-gradient(344.34deg, #1F1F2F 10.97%, #181824 52.25%);
      box-shadow: inset 0px -4px 6px rgba(0, 0, 0, 0.25), inset 0px 2px 10px #000000;
    }
    span {
      background: linear-gradient(146.99deg, #DBE0FF 18.8%, #F8F9FF 61.01%);;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: $light-purple;
      max-width: 700px;
      width: 100%;
      margin-bottom: 60px;
      display: block;
      font-size: 20px;
      line-height: 30px;
      font-family: $font-pb;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .form-send__wrapper {
      display: flex;
      @media (max-width: 1292px) {
        flex-direction: column;
      }
    }
    .form-send__input {
      max-width: 800px;
      width: 100%;
      margin-right: 80px;
      @media (max-width: 1292px) {
        max-width: 100%;
        margin-bottom: 50px;
      }
      label {
        color: #464667;
        @media (max-width: 480px) {
          font-size: 14px;
          left: 18px;
        }
      }
      input {
        background: #1F1F2F;
        color: #fff;
      }
    }
  }
  &__inner {
    margin: auto;
  }
}