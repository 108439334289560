.block {
  background-color: #fff;
  //background-image: url("../../img/Desktop/block-bg.svg");
  padding-top: 120px;
  background-position: right 120px;
  background-repeat: no-repeat;
  height: 750px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1700px) {
    padding-top: 80px;
  }
  @media (max-width: 1024px) {
   height: auto;
    padding-top: 60px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../img/Services/set.svg");
    width: 100%;
    height: 100%;
    z-index: 10;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__inner {
    position: absolute;
    left: 0;
    z-index: 101;
    padding-top: 150px;
    margin-left: 160px;
    @media (max-width: 1700px) {
      margin-left: 80px;
    }
    @media (max-width: 1024px) {
      margin-left: 20px;
      padding-top: 51px;
    }
    span {
      margin-top: 20px;
      display: block;
      font-size: 20px;
      line-height: 42px;
      color: $gray;
      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 21px;
      }
      @media (max-width: 600px) {
        width: 250px;
      }
    }
  }
  &__title {
    @include textGradientPurple();
    font-size: 76px;
    line-height: 100px;
    font-family: $font-pb;
    width: 728px;
    word-wrap: break-word;
    @media (max-width: 1024px) {
      font-size: 42px;
      line-height: 46px;
      width: 360px;
    }
    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 32px;
      width: 200px;
    }
  }
  &__bg {
    position: relative;
    top: 0;
    //right: 0;
    width: 1070px;
    height: 600px;
    background-image: url("../../img/Desktop/block-bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top right;
    clip-path: polygon(100% 94%,-77% 72%,0% 0,100% 0);
    @media (max-width: 1700px) {
      width: 100%;
      height: 463px;
      background-size: contain;
      clip-path: polygon(100% 91%,1% 62%,0 0,100% 0);
    }
    @media (max-width: 1024px) {
      clip-path: none;
      height: 320px;
    }
    @media (max-width: 600px) {
      height: 220px;
    }
    @media (max-width: 420px) {
      right: -100px;
    }
  }
  &__image {
    position: absolute;
    bottom: -150px;
    left: 60%;
    transform: translateX(-50%);
    //object-fit: contain;
    //width: 90%;
    //height: 90%;
    animation: cube-top 9s infinite;
    @media (max-width: 1700px) {
      bottom: 0;
      left: 77%;
    }
    @media (max-width: 1024px) {
      animation: none;
      bottom: 49px;
      left: 79%;
      width: 100%;
      height: 90%;
    }
    @media (max-width: 600px) {
      bottom: 30px;
    }
    @media (max-width: 400px) {
      bottom: 49px;
      left: 50%;
      width: 100%;
      height: 91%;
    }
    &:before {
      //content: "";
      position: absolute;
      top: 160px;
      left: 25%;
      background-image: url('../../img/Desktop/Map-cube.svg');
      background-repeat: no-repeat;
      width: 90%;
      height: 90%;

    }
    img {
      @media (max-width: 1700px) {
        width: 600px;
        height: 600px;
      }
      @media (max-width: 1024px) {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__decorAnim {
    position: absolute;
    left: 0;
    bottom: -100px;
    animation: cube-top 6s infinite ease-in-out;
    z-index: 0;
    @media (max-width: 1366px) {
      display: none;
    }
  }
}

.block-partner {
  background-color: rgb(19, 19, 28);
  .block__title {
    color: #fff;
    -webkit-text-fill-color: unset;
  }
  .block__bg {
    background-image: url("../../img/partners/bg.svg");
    .block__image {
      animation: inherit;
      bottom: 13px;

      @media (max-width: 1700px) {
        bottom: -37px;
      }

      @media (max-width: 1024px) {
        bottom: -16px;
        left: 94%;
      }

      @media (max-width: 420px) {
        left: 64%;
      }

      img {
        width: 883px;
        height: 628px;

        @media (max-width: 1024px) {
          width: 507px;
          height: 288px;
        }
        @media (max-width: 600px) {
          width: 180px;
          height: 120px;
        }
      }
    }
  }
}

@keyframes cube-top {
  0% {
    bottom: -150px;
  }
  50% {
    bottom: -100px;
  }
  100% {
    bottom: -150px;
  }
}