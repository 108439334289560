@font-face {
  font-family: "Poppins-Bold";
  src: url("/fonts/Poppins-Bold/Poppins-Bold.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Poppins-Bold/Poppins-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/Poppins-Bold/Poppins-Bold.otf") format("opentype"), /* Open Type Font */
  url("/fonts/Poppins-Bold/Poppins-Bold.svg") format("svg"), /* Legacy iOS */
  url("/fonts/Poppins-Bold/Poppins-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("/fonts/Poppins-Bold/Poppins-Bold.woff") format("woff"), /* Modern Browsers */
  url("/fonts/Poppins-Bold/Poppins-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("/fonts/Poppins-SemiBold/Poppins-SemiBold.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Poppins-SemiBold/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/Poppins-SemiBold/Poppins-SemiBold.otf") format("opentype"), /* Open Type Font */
  url("/fonts/Poppins-SemiBold/Poppins-SemiBold.svg") format("svg"), /* Legacy iOS */
  url("/fonts/Poppins-SemiBold/Poppins-SemiBold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("/fonts/Poppins-SemiBold/Poppins-SemiBold.woff") format("woff"), /* Modern Browsers */
  url("/fonts/Poppins-SemiBold/Poppins-SemiBold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  //font-display: swap;
  font-family: "Poppins-Medium";
  src: url("/fonts/Poppins-Medium/Poppins-Medium.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Poppins-Medium/Poppins-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/Poppins-Medium/Poppins-Medium.otf") format("opentype"), /* Open Type Font */
  url("/fonts/Poppins-Medium/Poppins-Medium.svg") format("svg"), /* Legacy iOS */
  url("/fonts/Poppins-Medium/Poppins-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
  url("/fonts/Poppins-Medium/Poppins-Medium.woff") format("woff"), /* Modern Browsers */
  url("/fonts/Poppins-Medium/Poppins-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("/fonts/Poppins-Regular/Poppins-Regular.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Poppins-Regular/Poppins-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/Poppins-Regular/Poppins-Regular.otf") format("opentype"), /* Open Type Font */
  url("/fonts/Poppins-Regular/Poppins-Regular.svg") format("svg"), /* Legacy iOS */
  url("/fonts/Poppins-Regular/Poppins-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("/fonts/Poppins-Regular/Poppins-Regular.woff") format("woff"), /* Modern Browsers */
  url("/fonts/Poppins-Regular/Poppins-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/fonts/Poppins-Light/Poppins-Light.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Poppins-Light/Poppins-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/Poppins-Light/Poppins-Light.otf") format("opentype"), /* Open Type Font */
  url("/fonts/Poppins-Light/Poppins-Light.svg") format("svg"), /* Legacy iOS */
  url("/fonts/Poppins-Light/Poppins-Light.ttf") format("truetype"), /* Safari, Android, iOS */
  url("/fonts/Poppins-Light/Poppins-Light.woff") format("woff"), /* Modern Browsers */
  url("/fonts/Poppins-Light/Poppins-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}