.reviews {
  background-color: #fff ;
  height: 900px;
  @media (max-width: 1024px) {
    //min-height: 600px;
    //min-height: 600px;
    height: auto;
    margin-bottom: 30px;
  }
  &--partners {
    background-color: $dark;
    .reviews__title {
      span {
        -webkit-text-fill-color: aliceblue;
        color: #fff;
      }
    }
    .reviews__slide {
      color: #fff;
    }
    .reviews__name {
      color: #fff;
    }
  }
  &__container {
    @include container();
    display: flex;
    justify-content: space-between;
  }
  &__desc {
    width: 30%;
    margin-top: 100px;
    @media (max-width: 1280px) {
      width: 40%;
    }
    @media (max-width: 1024px) {
      width: 100%;
      position: relative;
    }
  }
  &__title {
    margin-bottom: 40px;
    h2 {
      @include textGradientGreen();
      font-size: 48px;
      line-height: 72px;
      font-family: $font-pb;
      width: 400px;
      @media (max-width: 1024px) {
        width: 230px;
        font-size: 24px;
        line-height: 32px;
      }
    }
    span {
      @include textGradientPurple();
      font-size: 48px;
      line-height: 72px;
      font-family: $font-pb;
      @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  &__text {
    font-size: 16px;
    line-height: 32px;
    p {
      margin-bottom: 30px;
    }
  }
  &__name {
    font-size: 18px;
    line-height: 27px;
    color: $black;
    font-family: $font-psb;
    text-align: right;
    @media (max-width: 1024px) {
      text-align: left;
      padding-left: 80px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    span {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateX(-50%);
        left: -40px;
        width: 40px;
        height: 1px;
        background-color: #5000FF;
        @media (max-width: 1024px) {
          display: none;
        }
      }
    }
  }
  &__slides {
    position: relative;
    @media (max-width: 1024px) {
      top: -25px;
    }
  }
  &__slide {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 1s all ease-in-out;
    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      position: unset;
      display: none;
      height: 100%;
      opacity: 1;
    }
    &--active {
      opacity: 1;
    }
    &--activeB {
      display: flex;
    }
  }
  &__controlsSlide {
    display: none;
    @media (max-width: 1024px) {
      display: flex;
    }
    position: absolute;
    top: 95px;
    right: 20px;
    width: 60px;
    height: 25px;
    justify-content: space-between;
    z-index: 2;
    &:before {
      content: "";
      position: absolute;
      width: 3px;
      height: 3px;
      background: $linear-green-dark;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .reviews__prev,
    .reviews__next {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.slides-reviews {
  position: relative;
  width: 60%;
  top: 170px;
  @media (max-width: 1024px) {
    display: none;
  }
  &__item {
    width: 790px;
    height: 790px;
    border-radius: 100%;
    border: 1px solid #CED5FF;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation-name: rotate;
    animation-duration: 75s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    @media (max-width: 1600px) {
      width: 600px;
      height: 600px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 500px;
      left: 6px;
      width: 17px;
      height: 17px;
      border-radius: 100%;
      background: linear-gradient(270deg, #5000FF 0%, #9A6BFF 100%);
      animation: pulse 2s infinite;
      @media (max-width: 1600px) {
        top: 390px;
        left: 8px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 760px;
      left: 500px;
      width: 17px;
      height: 17px;
      border-radius: 100%;
      background: linear-gradient(270deg, #5000FF 0%, #9A6BFF 100%);
      animation: pulse 2s infinite;
      @media (max-width: 1600px) {
        top: 585px;
        left: 320px;
      }
    }
  }
  &__image1 {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 500px;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: linear-gradient(270deg, #5000FF 0%, #9A6BFF 100%);
      animation: pulse 2s infinite;
      @media (max-width: 1600px) {
        top: 30px;
        left: 446px;
      }
    }
    img {
      top: 277px;
      left: 735px;
      animation-name: rotate2;
      animation-duration: 75s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      @media (max-width: 1600px) {
        top: 350px;
        left: 555px;
      }
    }
  }
  &__image2 {
    img {
      top: 65px;
      left: 65px;
      animation-name: rotate2;
      animation-duration: 75s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      @media (max-width: 1600px) {
        left: 50px;
      }
    }
  }

  &__item-1 {
    width: 600px;
    height: 600px;
    border-radius: 100%;
    border: 1px solid #7BC9B0;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation-name: rotate-1!important;
    animation-duration: 100s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    @media (max-width: 1600px) {
      width: 440px;
      height: 440px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 56px;
      left: 100px;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: linear-gradient(90deg, #07A583 0%, #1ED18C 100%);
      animation: pulse2 2s infinite;
      @media (max-width: 1600px) {
        top: 22px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 75px;
      left: 500px;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: linear-gradient(90deg, #07A583 0%, #1ED18C 100%);
      animation: pulse2 2s infinite;
      @media (max-width: 1600px) {
        top: 390px;
        left: 340px;
      }
    }

  }
  &__image3 {
    img {
      top: 410px;
      left: 10px;
      animation-name: rotate2-1!important;
      animation-duration: 100s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      @media (max-width: 1600px) {
        top: 310px;
      }
    }
  }
  &__image4 {
    img {
      top: 400px;
      left: 500px;
      animation-name: rotate2-1!important;
      animation-duration: 100s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      @media (max-width: 1600px) {
        top: 115px;
        left: 390px;
      }
    }
  }
  &__item-2 {
    width: 370px;
    height: 370px;
    border-radius: 100%;
    border: 1px solid #CED5FF;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation-name: rotate;
    animation-duration: 100s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    @media (max-width: 1600px) {
      width: 270px;
      height: 270px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 280px;
      left: 330px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: linear-gradient(270deg, #5000FF 0%, #9A6BFF 100%);
      animation: pulse 2s infinite;
      @media (max-width: 1600px) {
        top: 223px;
        left: 222px;
      }
    }
    img {
      top: 0;
      left: 65px;
      animation-name: rotate2;
      animation-duration: 100s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      @media (max-width: 1600px) {
        left: 25px;
      }
    }
  }
  &__item-3 {
    width: 163px;
    height: 163px;
    border-radius: 100%;
    display: flex;
    //border: 1px solid $green;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //animation-name: rotate;
    //animation-duration: 15s;
    //animation-timing-function: linear;
    //animation-iteration-count: infinite;
    @media (max-width: 1600px) {
      width: 102px;
      height: 102px;
    }
    @media (max-width: 1024px) {
      width: 52px;
      height: 52px;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 158px;
      height: 158px;
      transform: translate(-50%,-50%);
      border-radius: 100%;
      transition: 1s all ease-in-out;
      //animation-name: rotate2;
      //animation-duration: 15s;
      //animation-timing-function: linear;
      //animation-iteration-count: infinite;
      opacity: 0;
      @media (max-width: 1600px) {
        width: 100px;
        height: 100px;
      }
      @media (max-width: 1024px) {
        width: 50px;
        height: 50px;
      }
    }
  }
  &__image1,
  &__image2,
  &__image3,
  &__image4,
  &__image5 {
    //position: relative;
    width: 90px;
    height: 90px;
    @media (max-width: 1600px) {
      width: 65px;
      height: 65px;
    }
    img {
      position: absolute;
      width: 90px;
      height: 90px;
      border-radius: 100%;
      transition: 1s all ease-in-out;
      opacity: 0;
      @media (max-width: 1600px) {
        width: 65px;
        height: 65px;
      }
    }
  }
  &__active {
    opacity: 1!important;
  }
  &__anim {
    animation-name: scarlet;
    animation-duration: 1s;
    //animation-timing-function: linear;
  }
  //&__image1,
  //&__image2,
  //&__image3,
  //&__image4,
  //&__image5 {
  //  width: 80px;
  //  height: 80px;
  //  img {
  //    width: 80px!important;
  //    height: 80px!important;
  //    object-fit: contain;
  //  }
  //}
}
.reviews__avatar {
  display: none;
  @media (max-width: 1024px) {
    display: block;
    top: 80px;
    left: 0;
    right: unset;
    bottom: unset;
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(80,0,255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 17px rgba(80,0,255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(80,0,255, 0);
  }
}
@keyframes pulse2 {
  0% {
    box-shadow: 0 0 0 0 rgba(30, 209, 140, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(30, 209, 140, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(30, 209, 140, 0);
  }
}
@keyframes scarlet {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-1 {
  0% {
    transform: rotate(-720deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes rotate2-1 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-720deg);
  }
}
