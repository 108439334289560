.header {
  background-color: rgba(255, 255, 255, 0.95);
  height: 60px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  transition: 1s all ease-in-out;
  @media (min-width: 1280px) {
    height: 80px;
  }
  @media (min-width: 1700px) {
    height: 120px;
    padding: 31px 0;
  }
  &--partners {
    background-color: rgba(19, 19, 28, 0.95);
    .main-nav__item a {
      color: #fff;
    }
    .main-nav__item--dropdown:after {
      background: url("../../img/Mob/icons/arrow-down-white.svg") no-repeat;
    }
    .header__inner {
      background: $dark;
      @media (max-width: 768px) {
        // top: 100px;
      }
    }
    .header__btn {
      //background: $dark;
    }
    .second-menu {
      @media (min-width: 1280px) {
        background: $dark;
      }
    }
    .second-menu__item a {
      color: #fff;
      background: #fff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .header__btn--sendMoney {
      margin-left: 0 !important;
    }
    .header__btn {
      background: $dark;
    }

    .header__toggle {
      color: #fff;
    }
    .header__wrap {
      background-color: $dark;
      // display: flex;
    }
    .header__btn--mob {
      display: none;
    }
    .header__actions {
      display: flex;
    }
  }
  &__container {
    @include container();
    display: flex;
    justify-content: space-between;
  }
  &__toggle {
    position: relative;
    padding-right: 20px;
    line-height: 21px;
    &:after {
      content: "";
      position: absolute;
      top: 30%;
      right: 0;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      background: $linear-green;
      border-radius: 100%;
    }
    @media (min-width: 1280px) {
      display: none;
    }
  }
  &__inner {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 100;
    padding-left: 20px;
    padding-right: 20px;
    background: $white;
    padding-top: 15px;
    padding-bottom: 100px;
    height: 100%;
    overflow: auto;

    //@media (max-width: 768px) {
    //  top: 100px;
    //}

    @media (min-width: 1280px) {
      position: sticky;
      display: flex;
      padding: 0;
      background: inherit;
      overflow: inherit;
      top: 0;
    }
    @media (min-width: 1280px) and (max-width: 1700px) {
      width: 90%;
    }
    @media (min-width: 1700px) and (max-width: 1800px) {
      width: auto;
      margin-left: auto;
    }
  }
  &__controls {
    border-bottom: 1px solid rgba(206, 213, 255, 0.5);
    @media (min-width: 1280px) {
      display: none;
    }
    a {
      font-family: $font-pr;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      margin-right: 15px;
      color: $gray;
      margin-bottom: 7px;
      display: inline-flex;
      &:last-child {
        margin-right: 0;
      }
    }
    .active-controls {
      font-family: $font-psb;
      background: $linear-green-dark;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: #049c7c;
    }
  }
  &__btn {
    margin-bottom: 10px;
    @include border-btn-gradient();

    @media (max-width: 440px) {
      padding: 6px 8px;
      margin-right: 4px !important;
      margin-left: 4px !important;
    }
    @media (max-width: 768px) {
      margin-bottom: 0;
    }

    @media (min-width: 1280px) {
      margin-bottom: 0;
      margin-right: 20px;
      padding: 10px 20px;
    }
    @media (min-width: 1380px) {
      margin-bottom: 0;
      margin-right: 35px;
      padding: 10px 30px;
    }
    &--sendMoney,
    &--partners {
      display: none;
      @media (min-width: 1280px) {
        display: block;
      }
    }
    &--transfer {
      @media (min-width: 1280px) {
        display: none;
      }
    }
    &--myAccount {
      @media (max-width: 1279px) {
        margin-right: 20px;
      }
      @media (min-width: 1280px) {
        padding: 0;
        background: inherit;

        &:after {
          content: none;
        }
      }
    }
    a {
      line-height: 18px;
      font-size: 14px;
      @media (min-width: 1280px) {
        font-size: 15px;
        line-height: 22px;
      }
      @media (min-width: 1700px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &--partners {
      @media (min-width: 1280px) {
        @include bg-btn-gradient();
        margin-right: 0;
        &:after {
          content: none;
        }
      }
      a {
        @media (min-width: 1280px) {
          color: $white;
          font-size: 15px;
          line-height: 22px;
        }
        @media (min-width: 1280px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &--sendMoney {
      @media (min-width: 1700px) {
        margin-right: 80px;
        position: relative;
        &:before {
          content: "";
          height: 76px;
          width: 1px;
          background: #ced5ff;
          position: absolute;
          top: -14px;
          right: -40px;
        }
      }
    }
    &--mob {
      @media (min-width: 1280px) {
        display: none;
      }

      //&:after {
      //  @media (max-width: 1279px) {
      //    content: none;
      //  }
      //}
    }
  }
  &__actions {
    @media (max-width: 1279px) {
      display: flex;
      display: none;
    }
    @media (min-width: 1280px) {
      display: flex;
      width: max-content;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &__wrap {
    display: none;
    @media (max-width: 768px) {
      position: fixed;
      top: 60px;
      left: 0;
      height: 40px;
      width: 100%;
      //margin: auto;
      background-color: #fff;
      display: none;
      justify-content: center;
      align-items: center;
    }
  }
}
.main-nav {
  margin-top: 30px;
  margin-bottom: 30px;
  @media (min-width: 1280px) {
    width: 630px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 8px;
    margin-left: auto;
    margin-right: auto;
  }
  &__items {
    @media (min-width: 1280px) {
      display: flex;
      position: relative;
      justify-content: center;
    }
  }
  &__item {
    width: 100%;
    margin-bottom: 15px;
    @media (min-width: 1280px) {
      width: auto;
      margin-right: 36px;
    }
    @media (min-width: 1400px) {
      margin-right: 40px;
    }
    @media (min-width: 1700px) {
      margin-right: 60px;
    }
    a {
      color: $black;
      //font-family: $font-pm;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      @media (min-width: 1280px) {
        font-size: 15px;
        line-height: 22px;
      }
      @media (min-width: 1700px) {
        font-size: 16px;
        line-height: 24px;
      }
      &:hover {
        background: $linear-green-dark;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #049c7c;
      }
    }
    &--active {
      a {
        background: $linear-green-dark;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #049c7c;
      }
    }
    &--dropdown {
      position: relative;
      @media (min-width: 1280px) {
        margin-right: 50px;
      }
      @media (min-width: 1400px) {
        margin-right: 60px;
      }
      @media (min-width: 1700px) {
        margin-right: 80px;
      }
      &:after {
        content: "";
        position: absolute;
        top: 15px;
        right: 0;
        width: 12px;
        height: 8px;
        background: url("../../img/Mob/icons/arrow-down.svg") no-repeat;
        @media (min-width: 1280px) {
          right: -20px;
        }
      }
      &.main-nav__item--active {
        &:after {
          background: url("../../img/Mob/icons/arrow-up.svg") no-repeat;
        }
      }
    }
    &--about,
    &--home {
      @media (min-width: 1280px) {
        display: none;
      }
    }
    
    &--about {
      a {
        @media (max-width: 1279px) {
          pointer-events: none;
        }
      }
    }
  }
}
.second-menu {
  display: none;
  padding-left: 15px;
  overflow: hidden;
  @media (min-width: 1280px) {
    position: absolute;
    top: 45px;
    left: 134px;
    background: $white;
    width: auto;
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px -5px rgba(80, 0, 255, 0.1);
  }
  @media (min-width: 1700px) {
    left: 95px;
  }
  &__item {
    margin-bottom: 10px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: -5px;
      bottom: 0;
      width: 0;
      height: 1px;
      background: #059c7c;
      transition: 1s all ease-in-out;
    }
    &:hover {
      &:before {
        width: 120%;
      }
    }
    a {
      font-size: 15px;
      line-height: 22px;
      font-weight: normal;
      color: $black;
      background: $black;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
