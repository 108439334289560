.community {
  background: $white;
  padding-top: 80px;
  padding-bottom: 200px;
  position: relative;
  @media (max-width: 1024px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  &__container {
    position: sticky;
  }
  &__title {
    text-align: center;
    margin-bottom: 100px;
    span {
      @include textGradientPurple();
    }
    h2 {
      @include title();
      @include textGradientGreen();
    }

    &--margin {
      @media (max-width: 1024px) {
        margin-bottom: 0!important;
      }

      h2 {
        @media (max-width: 1024px) {
          margin-bottom: 0!important;
        }
      }
    }
  }
  &__map {
    display: flex;
    justify-content: center;
    position: relative;
    width: 1200px;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 768px;
    }
    @media (max-width: 768px) {
      width: 320px;
    }
    svg {
      width: 1200px;
      @media (max-width: 1200px) {
        width: 768px;
      }
      @media (max-width: 768px) {
        width: 320px;
        height: 280px;
      }
    }
    svg g path {
      fill: #E4E8FF;
    }
    .rate,
    .africa,
    .arabia,
    .russian,
    .japan,
    .newYork,
    .avstraliya,
    .unitedKingdom {
      position: absolute;
      display: flex;
      flex-direction: column;
      &:before {
        content: "";
        position: absolute;
        top: 20px;
        left: 100px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: linear-gradient(90deg, #07A583 0%, #1ED18C 100%);
        animation: pulse2 2s infinite;
        @media (max-width: 768px) {
          left: 0;
        }
      }
      &__item, &__item-2 {
        position: absolute;
        width: max-content;
        opacity: 0;
        font-size: 13px;
        line-height: 19px;
      }
    }
    .rate__item,
    .africa__item,
    .arabia__item,
    .russian__item,
    .japan__item,
    .newYork__item,
    .avstraliya__item,
    .unitedKingdom__item {
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        font-size: 10px;
        line-height: 10px;
        align-items: flex-start;
      }
    }
    .rate {
      top: 340px;
      left: 220px;
      @media (max-width: 1200px) {
        left: 140px;
      }
      @media (max-width: 768px) {
        top: 145px;
        left: 80px;
      }
      &__item {
        &--active {
          animation: 1500ms linear 0s 1 normal forwards running showPrice;
        }
      }
    }
    .africa {
      top: 240px;
      left: 470px;
      @media (max-width: 1200px) {
        top: 280px;
        left: 250px;
      }
      @media (max-width: 768px) {
        left: 170px;
        top: 135px;
      }
      &__item {
        &--active {
          animation: 1000ms linear 0s 1 normal forwards running showPrice;
        }
      }
    }
    .arabia {
      top: 230px;
      left: 650px;
      @media (max-width: 1200px) {
        top: 250px;
        left: 370px;
      }
      &__item {
        &--active {
          animation: 2000ms linear 0s 1 normal forwards running arabia;
        }
      }
      &__item-2 {
        &--active {
          animation: 1000ms linear 0s 1 normal forwards running arabia;
        }
      }
    }
    .russian {
      top: 60px;
      left: 700px;
      @media (max-width: 1200px) {
        top: 140px;
        left: 460px;
      }
      @media (max-width: 768px) {
        top: 55px;
        left: 200px;
      }
      &__item {
        &--active {
          animation: 2000ms linear 0s 1 normal forwards running arabia;
        }
      }
    }
    .japan {
      top: 170px;
      left: unset;
      right: 250px;
      @media (max-width: 1200px) {
        top: 210px;
        right: 200px;
      }
      @media (max-width: 768px) {
        opacity: 0;
      }
      &__item {
        &--active {
          animation: 2000ms linear 0s 1 normal forwards running arabia;
        }
      }
    }
    .newYork {
      top: 170px;
      left: 130px;
      @media (max-width: 1200px) {
        top: 200px;
        left: 50px;
      }
      @media (max-width: 768px) {
        top: 80px;
        left: 50px;
      }
      &__item {
        &--active {
          animation: 2000ms linear 0s 1 normal forwards running arabia;
        }
      }
    }
    .avstraliya {
      bottom: 130px;
      right: 310px;
      @media (max-width: 1200px) {
        bottom: 205px;
        right: 240px;
      }
      @media (max-width: 768px) {
        opacity: 0;
      }
      &__item {
        &--active {
          animation: 2000ms linear 0s 1 normal forwards running arabia;
        }
      }
    }
    .unitedKingdom {
      top: 90px;
      left: 420px;
      @media (max-width: 1200px) {
        top: 160px;
        left: 230px;
      }
      @media (max-width: 768px) {
        opacity: 0;
      }
      &__item {
        &--active {
          animation: 2000ms linear 0s 1 normal forwards running arabia;
        }
      }
    }
    .itemShow {
      opacity: 0;
      transition: 2s all ease-in-out;
      &--active {
        opacity: 1;
      }
      &--active2 {
        opacity: 1;
      }
      &--active3 {
        opacity: 1;
      }
    }
  }
  &__text {
    display: flex;
    justify-content: center;
    padding: 80px 20px;

    p {
      text-align: center;
      max-width: 730px;
      width: 100%;
      font-size: 20px;
      line-height: 30px;
      color: $gray;
    }
  }
  &__wrapper {
    display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 900px;
    margin-bottom: 40px;
    position: relative;
    @media (max-width: 1024px) {
      width: 100% ;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -1px;
      width: 180px;
      height: 100%;
      background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      z-index: 1;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -1px;
      width: 120px;
      height: 100%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      z-index: 1;
    }
    &--partners {
      &:before {
        background: linear-gradient(-90deg, rgba(19, 19, 28, 0) 0%, #13131c 100%);
      }
      &:after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #13131c 100%);
      }
    }
  }
  &__sliders-left {
    //width: 900px;
  }
  &__items {
    display: flex;
    //width: 900px;
  }
  &__item {
    margin-right: 40px;
    width: 40px;
    height: 40px;
    display: block;
  }
  &__btn {
    @include border-btn-gradient-green();
    padding: 13px 80px;
    font-size: 16px;
    line-height: 24px;
    margin-top: 70px;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  &--partners {
    background: $dark;
    .community__title {
      h2 {

        span {
          -webkit-text-fill-color: aliceblue;
          color: #fff;
        }
      }
    }
    .community__btn {
      display: none;
    }
  }
}

@keyframes showPrice {
  0% {
    top: 0;
    opacity: 0;
  }
  25% {
    top: -5px;
    opacity: .3;
  }
  50% {
    top: -10px;
    opacity: .6;
  }
  75% {
    top: -20px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -30px;
  }
}
@keyframes arabia {
  0% {
    top: 0;
    opacity: 0;
  }
  25% {
    top: -5px;
    opacity: .3;
  }
  50% {
    top: -10px;
    opacity: .6;
  }
  75% {
    top: -20px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -30px;
  }
}

@keyframes pulseMap {
  0% {
    box-shadow: 0 0 0 0 rgba(30, 209, 140, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(30, 209, 140, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(30, 209, 140, 0);
  }
}