.services {
  background: $white;
  padding-bottom: 200px;
  position: relative;
  @media (max-width: 1024px) {
    padding-bottom: 80px;
    margin-top: 60px;
  }
  &--partners {
    background-color: $dark;
    .services__title {
      span {
        color: white;
        -webkit-text-fill-color: aliceblue;
      }
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }
    span {
      @include textGradientPurple;
      font-size: 48px;
      line-height: 72px;
      @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 32px;
      }
    }
    h2 {
      @include textGradientGreen;
      font-size: 48px;
      line-height: 72px;
      @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
  &__items {
    display: flex;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      padding: 0 20px;
      row-gap: 20px;
      column-gap: 20px;
    }
    @media (max-width: 700px) {
      column-gap: 0;
    }
    @media (max-width: 580px) {
      row-gap: 0;
    }
    &--partners {
      .services__item {
        background-color: #181824;
        border: 1px solid  #2a0080;
        color: #fff;
        &:hover {
          &:before {
            background-color: rgba(19, 19, 28, .8);
          }
        }
      }
      .services__more {
        background-color: rgba(19, 19, 28, 1);
        color: $linear-green;
      }
    }
  }
  &__item {
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right bottom;
    width: 320px;
    height: 380px;
    border: 1px solid #CED5FF;
    border-radius: 20px;
    padding: 40px 50px 40px 40px;
    margin-right: 50px;
    color: #0C0025;
    position: relative;
    cursor: pointer;
    word-wrap: break-word!important;
    white-space: normal!important;
    min-width: 320px;
    @media (max-width: 1024px) {
      width: 48%;
      height: auto;
      background-size: contain;
      padding: 20px 75px 45px 20px;
      margin-right: 0;
    }
    @media (max-width: 700px) {
      width: 100%;
      min-width: unset;
    }
    @media (max-width: 580px) {
      margin-bottom: 20px;
    }
    &:nth-child(1) {
      background-image: url("../../img/Desktop/icons/services/Group1.svg");
    }
    &:nth-child(2) {
      background-image: url("../../img/Desktop/icons/services/Group2.svg");
      background-position: right center;
    }
    &:nth-child(3) {
      background-image: url("../../img/Desktop/icons/services/Group3.svg");
    }
    &:nth-child(4) {
      background-image: url("../../img/Desktop/icons/services/Group4.svg");
    }
    &:nth-child(5) {
      background-image: url("../../img/Desktop/icons/services/Group5.svg");
      background-position: right center;
    }
    &:nth-child(6) {
      background-image: url("../../img/Desktop/icons/services/Group6.svg");
      background-position: left bottom;
      @media (max-width: 1024px) {
        background-image: url("../../img/Desktop/icons/services/Group6-mob.svg");
        background-position: right bottom;
      }
    }
    &:nth-child(7) {
      background-image: url("../../img/Desktop/icons/services/Group7.svg");
    }
    &:nth-child(8) {
      background-image: url("../../img/Desktop/icons/services/Group8.svg");
    }
    &:nth-child(9) {
      background-image: url("../../img/Desktop/icons/services/Group9.svg");
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0);
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
    &:hover {
      &:before {
        background-color: rgba(255, 255, 255, .8);
      }
      .services__desc {
        opacity: 1;
      }
    }
  }
  &__full-title {
    font-family: $font-pb;
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 20px;
    position: relative;
    z-index: 100;
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 26px;
      width: 185px;
    }
  }
  &__desc {
    position: sticky;
    word-wrap: break-word!important;
    white-space: normal!important;
    opacity: 0;
    transition: .5s all ease-in-out;

    @media (max-width: 1024px) {
      opacity: 1;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 20px;
      white-space: inherit;
      @media (max-width: 1700px){
        font-size: 16px;
      }
      @media (max-width: 1024px){
        display: none;
      }
    }
  }
  &__more {
    @include border-btn-gradient-green;
    padding: 13px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      width: 140px;
      padding: 8px 0;
      font-size: 12px;
      line-height: 14px;
    }
  }
  &__carousel {
    .simplemarquee-wrapper {
      margin-left: 0!important;
    }
  }
}
