.advantages {
  //background: $white;
  background-image: url("../../img/Desktop/advanture-bg.png");
  background-repeat: no-repeat;
  //background-color: $white;
  position: relative;
  padding-bottom: 200px;
  @media (max-width: 1700px) {
    background-size: 800px 600px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 120px;
  }
  @media (max-width: 580px) {
    background-image: url("../../img/Desktop/about-mmm.svg");
    background-size: 117%;
  }

  &:before {
    content: "";
    position: absolute;
    background: url("../../img/Desktop/icons/advanture/Mask-Group.svg") no-repeat;
    width: 100%;
    height: 60%;
    z-index: 0;
    animation: anim-up 5s ease-out infinite;
    overflow: hidden;
    //background: #1b3548;
    -webkit-clip-path: polygon(0 100%, 100% 75%, 100% 0, 0 0);
    clip-path: polygon(0 100%, 100% 75%, 100% 0, 0 0);
    @media (max-width: 1024px) {
      display: none;
      background: url("../../img/Desktop/Mob/Group.svg") no-repeat;
      background-size: 100%;
    }
  }
  //&:after {
  //  content: "";
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  background: #1b3548;
  //  width: 600px;
  //  height: 400px;
  //  -webkit-clip-path: polygon(0 100%, 100% 75%, 100% 0, 0 0);
  //  clip-path: polygon(0 100%, 100% 75%, 100% 0, 0 0);
  //}
  &--partners {
    background-color: $dark;
  }
  &__container {
    @include container();
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 480px) {
      padding-left: 0;
    }
  }
  &__desc {
    margin-top: 188px;
    color: $white;
    @media (max-width: 1700px) {
      width: 40%;
    }
    @media (max-width: 1024px) {
      margin-top: 70px;
      width: 100%;
      text-align: center;
    }
    @media (max-width: 768px) {
      display: flex;
      padding-left: 20px;
      flex-direction: column;
    }
  }
  &__title {
    font-size: 48px;
    line-height: 72px;
    font-family: $font-pb;
    display: block;
    margin-bottom: 40px;
    @media (max-width: 1024px) {
      margin-bottom: 0;
      width: 77%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 580px) {
      font-size: 28px;
      line-height: 32px;
      text-align: center;
    }
  }
  &__text {
    width: 550px;
    @media (max-width: 1700px) {
      width: 460px;
    }
    @media (max-width: 1366px) {
      width: 100%;
    }
    @media (max-width: 1024px) {
      width: 77%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    width: 850px;
    margin-top: 140px;
    @media (max-width: 1700px) {
      width: 60%;
    }
    @media (max-width: 1024px) {
      width: 100%;
      // row-gap: 20px;
      // column-gap: 20px;
      margin-top: 34px;    
    }
    @media (max-width: 580px) {
      flex-wrap: nowrap;
      width: 110%;
      overflow: scroll;
      padding-left: 20px;
      &::-webkit-scrollbar { width: 0; }
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      // row-gap: 0;
      // column-gap: 0;
  
    }
    &--partners {
      color: #fff;
      .advantages__item {
        background: #181824;
      }
    }
  }
  &__item {
    width: 400px;
    height: 270px;
    background: $white;
    box-shadow: 0px 10px 20px -5px rgba(80, 0, 255, 0.15);
    border-radius: 20px;
    margin-right: 50px;
    margin-bottom: 40px;
    padding: 50px 50px 40px;
    transform-style: preserve-3d;
    @media (max-width: 1780px) {
      width: 48%;
      height: 237px;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 40px;
    }
    @media (max-width: 1210px) {
      height: auto;
    }
    @media (max-width: 1024px) {
      width: 30%;
      padding: 40px 20px;
      // margin-right: 0;
      // margin-bottom: 0;
      &:nth-child(2n) {
        margin-right: 20px!important;
      }
    }
    @media (max-width: 768px) {
      width: 46%;
    }
    @media (max-width: 580px) {
      min-width: 250px;
      height: auto;
      margin-right: 20px;
    }
    &:nth-child(2n) {
      margin-right: 0;

      @media (max-width: 1024) {
        margin-right: 20px!important;
      }

      @media (max-width: 580px) {
        margin-right: 20px;
      }
    }
  }
  &__icon {
    img {
      width: 60px;
      height: 60px;
      transform: translateZ(30px);
      position: relative;
      max-width: initial;
    }
  }
  &__info {
    //transform: translateZ(30px);
  }
}
@keyframes anim-up {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);  }
}