* {
  box-sizing: border-box;
  transition: all 1s ease-in;
}

html,
body {
  height            : 100%;
  font-family       : $font-pr;
  font-size         : 18px;
  line-height       : 36px;
  letter-spacing    : 0.05em;
  //background-color: #efbd3d;
  background-color  : #fff;
  color             : $black;
  max-width         : 1920px;
  margin            : 0 auto;

  @media(max-width: 1700px) {
    font-size  : 16px;
    line-height: 32px;
  }

  @media(max-width: 1024px) {
    font-size  : 14px;
    line-height: 26px;
  }
}

.page {
  display       : flex;
  flex-direction: column;
  overflow      : hidden;
  min-height    : 100vh;

  &--partners {
    background: $dark;
  }
}

footer {
  margin-top: auto;
  position  : relative;
}

a {
  text-decoration: none;
  color          : $dart-green;
}

ul {
  margin : 0;
  padding: 0;
}

li {
  list-style: none;
}

blockquote,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin : 0;
  padding: 0;
}

input {
  outline: none;
}

button {
  outline: none;
}

.overflow-hidden {
  overflow: hidden;
}

// Preloader
$loader--width   : 150px;
$loader-dot--size: 12px;

.preloader {
  background     : white;
  position       : fixed;
  top            : 0;
  left           : 0;
  width          : 100%;
  height         : 100%;
  display        : flex;
  align-items    : center;
  justify-content: center;
  z-index        : 1000;

  img {
    width : 150px;
    height: 150px;
  }

  .loader {
    height  : $loader-dot--size;
    width   : $loader--width;
    position: absolute;
    top     : 90px;
    bottom  : 0;
    left    : 0;
    right   : 0;
    margin  : auto;

    &--dot {
      animation    : loader ease-in-out 3s infinite;
      height       : $loader-dot--size;
      width        : $loader-dot--size;
      border-radius: 100%;
      background   : $linear-green-dark;
      position     : absolute;

      &:first-child {
        //background: $linear-green-dark;
        animation-delay: 0.5s;
      }

      &:nth-child(2) {
        //background: $linear-green-dark;
        animation-delay: 0.4s;
      }

      &:nth-child(3) {
        //background: $linear-green-dark;
        animation-delay: 0.3s;
      }

    }
  }
}

.partners {

  //background-color: #13131c;;
  background: url("../../img/partners/about-bg1.png") no-repeat,
  url("../../img/EARTH/dark_end frame/dark_end frame.png") no-repeat;
  //url("../../img/partners/about-bg1.png") no-repeat;
  background-size      : auto, auto;
  background-position  : 0 1100px, 0% 0;
  //background-size: contain;
  background-position-x: center;

  .main-banner {
    height: 100vh;
  }

  .main-banner__bg {
    height: 100vh;
  }
}


.about-page._about-page-partner {
  background: #13131C;

  color: #fff;

  .about__card {
    background-color  : #181824;
    -webkit-box-shadow: 0 10px 20px -5px #0b0b11;
    box-shadow        : 0 10px 20px -5px #0b0b11;

    b {
      -webkit-text-fill-color: white;
    }
  }

  .about__desc {
    color: #fff;
  }  

  &.about-page .about__element .about__desc h3 {
    -webkit-text-fill-color: white !important;
  }

  .about__btn {
    background: #13131c;
  }

  &.about-page .about__element:nth-child(1) .about__desc:before {
    background-image: url(../../img/partners/bg/2.png);
  }

  &.about-page .about__element:nth-child(2) .about__desc:before {
    background-image: url(../../img/partners/bg/3.png);
  }

  &.about-page .about__element:nth-child(3) .about__desc:before {
    background-image: url(../../img/partners/bg/4.png);
  }
}


.about__desc li:before {
  content: "";
  background: linear-gradient(90deg,#049c7c 0,#19a27f 9.81%,#31ae84 35.26%,#3bb688 63.52%,#3db989 99.99%);
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  vertical-align: top; 
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 10px;
}

.about__desc li p {
  display: inline-block;
}

///

.block {
  img {

    &:last-child {
      display: none;
    }
  }
  .block__image {

    img {

      &:last-child {
        display: none;
      }
    }
  }

}

.block._about-page-partner {
  background: #13131C;

  color: #fff;

  .block__bg {
    background-image: url(../../img/partners/bg/Rectangle66.png);
  }

  .block__title {
    -webkit-text-fill-color: white;
  }

  .block__image {

    img {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }

  .block__decorAnim {

    img {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }
}

.news._news-partner {
  background: #13131C;

  .news__title {
    background             : -webkit-gradient(linear, left top, right top, from(#049c7c), color-stop(9.81%, #19a27f), color-stop(35.26%, #31ae84), color-stop(63.52%, #3bb688), color-stop(99.99%, #3db989));
    background             : -o-linear-gradient(left, #049c7c 0, #19a27f 9.81%, #31ae84 35.26%, #3bb688 63.52%, #3db989 99.99%);
    background             : linear-gradient(90deg, #049c7c 0, #19a27f 9.81%, #31ae84 35.26%, #3bb688 63.52%, #3db989 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color                  : #059c7c;

    span {
      -webkit-text-fill-color: #f0f8ff;
    }
  }

  .news__item {
    background: #13131C;
    border    : 1px solid #1F1F2F;
  }

  .news__full-title {
    color: #fff;
  }

  .news__more {
    background: #13131c;
  }
}

.news__decorAnim {
  img {

    &:last-child {
      display: none;
    }
  }
}

.news__decorAnim._about-page-partner {
  img {
    &:first-child {
      display: none;
    }

    &:last-child {
      display: block;
    }
  }
}

.guavapay-network._guavapay-network-partner {
  background: #13131C;
  color: #fff;
}

.accordion._accordion--partner {
  background: #13131C;

  .accordion__title {
    -webkit-text-fill-color: #f0f8ff;

    span {
      background             : -webkit-gradient(linear, left top, right top, from(#049c7c), color-stop(9.81%, #19a27f), color-stop(35.26%, #31ae84), color-stop(63.52%, #3bb688), color-stop(99.99%, #3db989));
      background             : -o-linear-gradient(left, #049c7c 0, #19a27f 9.81%, #31ae84 35.26%, #3bb688 63.52%, #3db989 99.99%);
      background             : linear-gradient(90deg, #049c7c 0, #19a27f 9.81%, #31ae84 35.26%, #3bb688 63.52%, #3db989 99.99%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color                  : #059c7c;
    }
  }

  .accordion__header {
    background: #181824;
    box-shadow: 0px 10px 20px -5px #0B0B11;
    border: none;

    h2 {
      background             : linear-gradient(90deg, #049c7c 0, #026a53 99.99%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color                  : #059c7c;
    }
  }
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.nav-footer__submenu {
  @media (max-width: 1024px) {
    display: block;
  }

  @media (max-width:480px) {
    display: none;
  }
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }

  45% {
    transform: translateX($loader--width - $loader-dot--size);
  }

  65% {
    transform: translateX($loader--width - $loader-dot--size);
  }

  95% {
    transform: translateX(0);
  }
}

@keyframes animateRooter {
  0% {
    transform: translateY(-50%);
  }

  50% {
    transform: translateY(-40%);
  }

  100% {
    transform: translateY(-50%);
  }
}