@mixin title {
  font-size: 48px;
  line-height: 72px;
  font-family: $font-pb;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 50px;
  }
  @media (max-width: 480px) {
    font-size: 22px;
    line-height: 28px;
  }
}
@mixin textGradientPurple {
  background: $linear-dark-purple;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: $purple;
}
@mixin textGradientGreen {
  background: $linear-green-dark;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: $green;
}
@mixin textGradientGreenLight {
  background: $linear-green-light;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: $green;
}