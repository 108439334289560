.circle-progress {
  position: relative;
  width: 200px;
  height: 200px;
}

.circle-wrapper {
  position: absolute;
  top: 0;
  width: 83px;
  height: 200px;
  overflow: hidden;
  @media (max-width: 1600px) {
    width: 52px;
  }
  @media (max-width: 1024px) {
    width: 26px;
  }
}

.circle-wrapper_right {
  right: 0;
}

.circle-wrapper_left {
  left: 0;
}

.circle {
  position: absolute;
  top: 0;
  width: 162px;
  height: 162px;
  border: 2px solid transparent;
  border-radius: 100%;
  @media (max-width: 1600px) {
    width: 104px;
    height: 104px;
  }
  @media (max-width: 1024px) {
    width: 52px;
    height: 52px;
  }
}

.circle_right {
  right: 0;
  border-top: 2px solid $light-green;
  border-right: 2px solid $light-green;
  animation: right-progress 10s linear infinite;
  @media (max-width: 1600px) {
  }
}

.circle_left {
  left: 0;
  border-bottom: 2px solid $light-green;
  border-left: 2px solid $light-green;
  animation: left-progress 10s linear infinite;
  @media (max-width: 1600px) {
  }
}

@keyframes right-progress {
  0% {
    transform: rotate(-135deg);
  }
  50%,
  100% {
    transform: rotate(45deg);
  }
}

@keyframes left-progress {
  0%,
  50% {
    transform: rotate(-135deg);
  }
  100% {
    transform: rotate(45deg);
  }
}