.footer {
  position: relative;
  height: 1100px;
  z-index: 4;
  @media (max-width: 1024px) {
    height: 1400px;
    padding-top: 400px;
  }
  @media (max-width: 768px) {
    height: 1500px;
    padding-top: 400px;
  }

  @media (max-width: 425px) {
    height: 1760px;
    padding-top: 400px;
  }

  &--partners {
    .footer__top {
      background-image: url("../../img/Desktop/bg/bg-partners.svg");
      @media (max-width: 1024px) {
        background-image: url("../../img/Desktop/bg/bg-partners-mob.svg");
      }
    }
    .footer__title {
      h2 {
        span {
          -webkit-text-fill-color: aliceblue;
          color: #fff;
        }
      }
    }
    .footer__text {
      color: #fff;
    }
  }
  &__top {
    //background-image: url("../../img/Desktop/bg/footer-app.svg");
    background-image: url("../../img/Desktop/bg/bg.svg");
    //background-position: left bottom;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    height: 1100px;
    width: 100%;
    @media (max-width: 1024px) {
      background-image: url("../../img/Desktop/bg/bg-footer-mob.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 0 0;
      height: 100%;
    }
  }
  &__desc {
    margin-top: 260px;
    display: flex;
    align-items: center;
    padding-left: 162px;
    @media (max-width: 1400px) {
      padding-left: 80px;
    }
    @media (max-width: 1024px) {
      margin-top: 80px;
      flex-direction: column;
      padding-left: 0;
    }
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
  &__title {
    margin-bottom: 30px;
    max-width: 660px;
    width: 100%;
    @media (max-width: 1280px) {
      max-width: 360px;
    }
    @media (max-width: 1024px) {
      max-width: 300px;
      text-align: center;
      margin-top: 130px;
    }
    h2 {
      @include textGradientGreen();
      font-size: 48px;
      line-height: 72px;
      font-family: $font-pb;
      @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 32px;
      }
    }
    span {
      @include textGradientPurple();
      font-size: 48px;
      line-height: 72px;
      font-family: $font-pb;
      @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
  &__text {
    @media (max-width: 1280px) {
      max-width: 380px;
      width: 100%;
    }
    @media (max-width: 1024px) {
      max-width: 295px;
      text-align: center;
    }
    p {
      font-size: 18px;
      line-height: 36px;
      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
  &__app {
    display: flex;
    margin-left: 80px;
    @media (max-width: 1400px) {
      margin-left: 40px;
    }
    @media (max-width: 1024px) {
      margin-left: 0;
    }
    span {
      font-family: $font-pl;
      font-size: 14px;
      line-height: 21px;
      @media (max-width: 1600px) {
        font-size: 12px;
      }
    }
  }
  &__apple,
  &__android {
    @include border-btn-gradient-green();
    padding: 15px 46px 15px 90px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: 30px;
    color: $black;
    @media (max-width: 1600px) {
      font-size: 16px;
      line-height: 20px;
      padding: 15px 30px 15px 70px;
    }
    @media (max-width: 1024px) {
      display: none;
    }
    &:before {
      content: "";
      position: absolute;
      top: 20px;
      left: 30px;
      background-image: url("../../img/Desktop/Icon/apple.png");
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: 100%;
      @media (max-width: 1600px) {
        width: 30px;
        height: 30px;
      }
    }
    &:nth-child(2){
      margin-right: 0;
      &:before {
        background-image: url("../../img/Desktop/Icon/google-play.png");
      }
    }
    &:nth-child(3){
      margin-right: 0;
      &:before {
        background-image: unset;
      }
    }
  }
  &__getApp {
    display: none!important;
    @media (max-width: 1024px) {
      display: block!important;
      padding: 10px 50px;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      background:  linear-gradient(270deg, #5000FF 0%, #9A6BFF 100%);
      border-radius: 100px;
      margin-top: 30px;
    }
  }
  &__bottom {
    height: 728px;
    width: 100%;
    background-image: url("../../img/Desktop/bg/footer-bg.svg");
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    bottom: 0;
    color: $white;
    z-index: 10;
    @media (max-width: 1300px) {
      background-position: -140px 0;
    }
    @media (max-width: 1024px) {
      background-image: url("../../img/Desktop/bg/footer-bg-md.svg");
      background-size: 100%;
      background-position: 0px 0;
      height: 100%;
      position: relative;
      //margin-top: 400px;
    }
    @media (max-width: 480px) {
      &:before {
        content: "";
        background: $linear-green-dark;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        z-index: -1;
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: url("../../img/Desktop/icons/footer-vector.svg") no-repeat;
      width: 422px;
      height: 667px;
      opacity: .2;
      animation: animateRooter 5s ease-out infinite;
      @media (max-width: 1700px) {
        background: url("../../img/Desktop/icons/footer-vector-lj.svg") no-repeat;
        width: 318px;
        height: 540px;
      }
    }
    a {
      color: $white;
      //font-size: 18px;
      //line-height: 27px;
    }
    h3 {
      font-family: $font-pb;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 24px;
      @media (max-width: 1700px) {
        font-size: 16px;
      }
    }
    h4 {
      font-weight: bold;
      @media (max-width: 1700px) {
        font-size: 16px;
        font-family: $font-pb;
        margin-bottom: 10px;
        line-height: 24px;
      }
    }
  }
  &__container {
    display: flex;
    position: absolute;
    bottom: 50px;
    padding-left: 323px;
    z-index: 10;
    @media (max-width: 1700px) {
      bottom: 40px;
      padding-left: 243px;
    }
    @media (max-width: 1300px) {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 50px;
      width: 100%;
      justify-content: space-between;
    }
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      height: 100%;
      position: relative;
      padding-top: 230px;
      padding-bottom: 50px;
      padding-right: 100px;
    }
    @media (max-width: 768px) {
      padding-top: 200px;
      padding-right: 30px;
    }
    @media (max-width: 480px) {
      padding-top: 100px;
      padding-right: 30px;
      padding-bottom: 0;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
      width: 33.3%;
      align-items: center;
      margin-bottom: 30px;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 480px) {
      width: 100%;
      align-items: unset;
    }
    &:nth-child(1) {
      margin-right: 117px;
      @media (max-width: 1700px) {
        margin-right: 160px;
      }
      @media (max-width: 1300px) {
        margin-right: 0;
        margin-left: 30px;
      }
      @media (max-width: 1024px) {
        margin-left: 0;
        order: 1;
        margin-top: 80px;
      }
      @media (max-width: 768px) {
        margin-left: 0;
        order: 1;
        margin-top: 0;
      }
      @media (max-width: 480px) {
        order: 1;
      }
    }
    &:nth-child(2) {
      margin-right: 80px;
      @media (max-width: 1700px) {
        margin-right: 76px;
      }
      @media (max-width: 1300px) {
        margin-right: 0;
      }
      @media (max-width: 1024px) {

      }
      @media (max-width: 768px) {
        //padding-top: 40px;
      }
      @media (max-width: 480px) {
        padding-top: 0;
      }
    }
    &:nth-child(3) {
      margin-right: 171px;
      //justify-content: flex-end;
      @media (max-width: 1700px) {
        margin-right: 75px;
        justify-content: unset;
      }
      @media (max-width: 1300px) {
        margin-right: 0;
      }
      @media (max-width: 1024px) {
        display: none;
      }
    }
    &:nth-child(4) {
      @media (max-width: 1024px) {
        margin-top: 80px;
      }
    }
  }
  &__logo {
    margin-bottom: 50px;
    @media (max-width: 1700px) {
      margin-bottom: 40px;
    }
    @media (max-width: 1024px) {
      display: none;
    }

    &--xs {
      img {
        width: 120px;
      }
      display: none;
      @media (max-width: 1024px) {
        margin-bottom: 23px;
        display: block;
      }
    }
  }

  &__socials {
    margin-bottom: 50px;
    @media (max-width: 1700px) {
      margin-bottom: 40px;
    }
    @media (max-width: 1024px) {
      order: 1;
    }
    @media (max-width: 480px) {
      order: 0;
    }
    ul {
      display: flex;
      li {
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__support {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    @media (max-width: 1700px) {
      margin-bottom: 40px;
    }
    a {
      margin-bottom: 10px;
    }
  }
  &__copyright {
    font-size: 12px;
    line-height: 18px;
    font-family: $font-pm;

    @media (max-width: 1024px) {
      display: none;
    }

    @media (max-width: 480px) {
      display: block;
    }

    &--table {
      display: none;
      @media (max-width: 1024px) {
        margin-top: 20px;
        display: block;
      }

      @media (max-width: 480px) {
        display: none;
      }
    }
  }
  &__btn {
    @include border-btn-white();
    a {
      font-size: 15px;
      line-height: 22px;
      @media (max-width: 1700px) {
        font-size: 14px;
      }
    }
  }
  &__account {
    margin-bottom: 15px;
  }
}
.nav-footer {
  margin-bottom: 38px;
  @media (max-width: 1700px) {
    margin-bottom: 10px;
  }
  &__item {
    font-weight: bold;
    margin-bottom: 15px;
    @media (max-width: 480px) {
      line-height: 24px;
    }
    &--sub {
      margin-top: -15px;
      a {
        font-size: 15px;
        line-height: 22px;
        font-weight: 300;
        @media (max-width: 1700px) {
          font-size: 14px;
        }
        @media (max-width: 480px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &--dropdown {
      @media (max-width: 1024px) {
        position: relative;
        &:after {
          content: "";
          background: url("../../img/Mob/icons/arrow-down-white.svg") no-repeat;
          width: 12px;
          height: 6px;
          position: absolute;
          right: -20px;
          top: 8px;
          transition: 1s all ease-in-out;
        }
      }
      @media (max-width: 480px) {
        &:after {
          right: 0;
        }
      }
    }
    &--active {
      @media (max-width: 1024px) {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }
  }
  &__submenu {
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.services-f {
  &__item {
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 22px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.footer__support,
.nav-footer__item,
.services-f__item {
  a {
    display: inline-block;
    outline: none;
    color: #fff;
    text-decoration: none;
    color: rgba(0,0,0,0.2);
    text-shadow: none;
    position: relative;
    &:before {
      color: #fff;
      content: attr(data-hover);
      position: absolute;
      transition: transform 0.3s, opacity 0.3s;
    }
    &:hover {
      &:before {
        transform: scale(0.9);
        opacity: 0;
      }
    }
    &:focus {
      &:before {
        transform: scale(0.9);
        opacity: 0;
      }
    }
  }
}

.our-service-footer-item {
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
}

.spec-class-footer {
  display: none;
}

@media (max-width: 1024px) {
  .spec-class-footer {
    left: -10px;
    display: block;
  }
  .spec-class-footer-block {
    margin-left: 70px;
    display: block;
  }
}

@media (max-width: 768px) {
  .spec-class-footer-block {
    display: block;
  }
}

//@media (max-width: 700px) {
//  .spec-class-footer-block {
//    display: none;
//  }
//}

@media (max-width: 475px) {
  .spec-class-footer {
    left: 0;
  }
  .spec-class-footer-block {
    margin-left: 0;
  }
}
