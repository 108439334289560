.partners-title {
  h2 {
    @include textGradientGreenLight();
  }
}
.partners-btn {
  background: $linear-green;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: $green;
  a {
    background: $linear-green;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: $green;
    &:hover {
      background: $linear-green-dark;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: $green;
    }
  }
  &:after {
    background: $linear-green;
  }
  &:hover {
    &:after {
      background: $linear-green-dark;
    }
  }
}