.panel-btn {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  height: 60px;
  width: 100%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -4px 15px rgba(2, 106, 83, 0.1);
  @media (max-width: 1280px) {
    display: flex;
  }
  &--partners {
    background-color: $dark;
    .panel-btn__get-App {
      background-color: $dark;
    }
    .panel-btn__online {
      background-color: $dark;
    }
  }
  &__online {
    @include border-btn-gradient();
    padding: 10px 20px;
    margin-right: 14px;
  }
  &__get-App {
    @include border-btn-gradient();
    padding: 10px 14px;
    font-size: 12px;
    line-height: 18px;
  }
  &__active {
    color: #fff;
    background: $linear-green-dark;
  }
}