.content {
  padding: 0 323px;
  @media (max-width: 1700px) {
    padding: 0 242px;
  }
  @media (max-width: 1366px) {
    padding: 0 80px;
  }
  @media (max-width: 1024px) {
    padding: 0 20px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    line-height: 42px;
    p {
      margin-bottom: 50px;
    }
    b {
      margin-bottom: 50px;
      font-size: 20px;
      font-family: $font-pb;
      @media (max-width: 1024px) {
        font-size: 18px;
      }
    }
    strong {
      font-family: $font-psb;
      font-weight: 500;
    }
    ul {
      li {
        position: relative;
        padding-left: 15px;
        &:before {
          content: "";
          position: absolute;
          top: 15px;
          left: 0;
          width: 3px;
          height: 3px;
          background: $linear-green-dark;
        }
      }
    }
  }
}